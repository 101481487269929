let outlet=
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="1261.000000pt" height="1053.000000pt" viewBox="0 0 1261.000000 1053.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,1053.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M6596 10083 c-2 -2 -136 -4 -297 -4 -279 0 -329 -4 -316 -26 4 -6 -5
-16 -19 -24 -22 -11 -27 -11 -34 1 -12 19 -12 30 -1 30 5 0 12 -8 15 -17 6
-15 9 -15 16 -3 22 35 -17 38 -215 15 -181 -21 -488 -71 -508 -82 -5 -3 -34
-7 -65 -9 -69 -5 -178 -37 -175 -52 1 -5 -7 -8 -20 -5 -18 4 -19 3 -7 -7 12
-9 10 -11 -12 -6 -15 3 -39 1 -55 -5 -15 -6 -52 -17 -81 -25 -149 -40 -429
-153 -677 -274 -77 -38 -170 -82 -207 -99 -62 -27 -148 -85 -156 -104 -2 -4
-26 -19 -54 -33 -98 -50 -409 -263 -483 -331 -30 -27 -39 -33 -51 -33 -4 0 -1
-7 6 -15 10 -12 8 -13 -10 -10 -18 3 -50 -23 -156 -127 -131 -129 -134 -132
-116 -152 17 -21 17 -21 -4 -3 -20 17 -22 15 -90 -56 -38 -40 -99 -106 -136
-147 -63 -69 -68 -72 -76 -51 -8 22 -11 19 -59 -39 -28 -34 -63 -86 -78 -116
-15 -29 -36 -56 -46 -59 -22 -7 -102 -116 -124 -168 -8 -19 -36 -64 -62 -99
-77 -101 -91 -127 -241 -431 -88 -180 -149 -317 -162 -365 -11 -42 -44 -154
-72 -249 l-52 -171 -36 -6 c-66 -11 -148 -7 -153 8 -4 9 -33 16 -94 21 -48 4
-90 11 -93 16 -9 14 -281 15 -307 1 -12 -7 -60 -12 -107 -12 -52 0 -88 -4 -92
-11 -7 -11 -83 -8 -148 7 -21 5 -27 4 -22 -5 4 -6 1 -11 -8 -11 -10 0 -16 -9
-16 -21 0 -20 -3 -21 -53 -13 -108 16 -163 6 -172 -35 -5 -23 0 -34 26 -60 20
-21 39 -31 50 -28 10 3 36 1 56 -5 21 -5 131 -11 244 -13 181 -3 384 -16 473
-30 24 -4 28 -2 23 15 -4 15 0 20 16 20 16 0 18 -3 9 -9 -10 -6 -6 -10 15 -14
15 -3 31 -1 34 5 3 5 26 8 50 6 35 -2 43 -6 41 -20 -4 -22 1 -22 40 -2 20 11
43 14 67 10 72 -13 131 -15 125 -5 -4 7 7 9 27 7 25 -2 34 -8 33 -20 -2 -16 8
-18 70 -17 39 0 103 -2 141 -6 47 -4 65 -3 55 4 -23 16 -10 20 83 27 68 4 93
2 118 -10 40 -20 69 -20 69 -1 0 17 17 19 131 15 48 -1 79 2 83 9 4 7 34 11
71 10 34 -1 67 1 73 5 6 3 12 1 14 -5 2 -7 12 -7 31 0 22 9 33 8 50 -4 29 -20
113 -20 121 0 3 8 10 15 15 15 6 0 8 -6 6 -12 -4 -9 6 -13 30 -14 30 0 34 2
29 18 -6 21 16 24 49 6 40 -21 101 -27 186 -16 62 7 91 7 110 -2 17 -8 27 -8
29 -1 7 20 70 23 134 7 41 -10 91 -14 146 -11 48 2 80 0 77 -5 -6 -9 166 -5
248 5 20 3 37 1 37 -4 0 -5 10 -7 22 -3 13 3 19 1 15 -5 -5 -7 11 -12 43 -15
41 -2 50 0 50 13 0 29 58 31 115 4 55 -26 121 -44 95 -26 -28 20 -1 22 64 6
68 -17 101 -13 70 9 -14 10 0 12 76 10 54 -2 100 1 112 7 12 6 35 15 52 20 29
8 30 7 12 -6 -19 -15 -19 -15 2 -15 12 0 22 -4 22 -9 0 -5 26 -8 58 -5 37 3
50 1 37 -4 -19 -9 -19 -9 3 -16 12 -4 35 -5 50 -2 15 4 70 8 122 10 52 3 109
7 125 11 17 3 37 1 45 -5 13 -9 13 -11 0 -21 -11 -8 -8 -9 15 -3 41 11 110 10
170 -3 27 -6 54 -8 60 -3 5 4 37 11 70 14 l60 7 -35 -20 -35 -21 45 7 c62 8
93 21 84 34 -5 8 6 9 37 4 24 -5 89 -9 144 -10 55 0 114 -5 130 -10 27 -8 29
-7 16 8 -15 19 -11 22 53 31 31 4 39 2 43 -13 3 -12 13 -18 29 -17 13 0 24 4
24 7 0 15 44 17 66 2 15 -9 33 -13 50 -9 17 5 23 3 19 -4 -4 -7 30 -10 104
-10 72 0 111 4 111 11 0 6 8 8 20 4 10 -3 32 0 47 8 23 13 33 13 67 1 32 -12
41 -12 48 -2 4 7 8 16 8 19 0 6 91 0 99 -7 2 -2 -2 -10 -9 -19 -11 -13 -6 -15
39 -15 28 0 51 3 51 8 0 4 39 8 86 9 47 0 101 4 121 8 24 4 33 3 28 -4 -8 -13
24 -26 85 -34 44 -7 45 -7 18 8 -16 9 -28 18 -28 21 0 9 91 -6 113 -17 12 -7
39 -4 83 7 62 15 81 15 150 -2 26 -6 27 -5 16 9 -20 23 13 30 90 17 82 -13
161 -13 153 0 -3 6 -16 10 -28 11 -14 1 -10 4 13 9 34 8 54 5 46 -8 -2 -4 34
-7 80 -7 46 0 84 3 84 8 0 4 10 7 22 7 21 0 21 0 2 16 -18 14 -18 15 6 9 14
-4 39 -13 55 -20 20 -9 60 -13 113 -11 74 2 103 -5 70 -17 -9 -3 -8 -7 4 -12
9 -4 43 0 75 8 58 15 87 12 62 -5 -28 -19 56 4 90 25 52 31 136 19 114 -17 -4
-5 4 -7 18 -3 13 4 42 7 64 8 22 0 34 3 28 6 -25 10 -13 22 13 13 14 -6 23 -6
20 -2 -5 8 133 7 227 -3 45 -5 57 -3 51 6 -5 9 -1 10 17 5 53 -17 80 -17 155
-2 80 17 93 14 94 -22 0 -9 9 -12 28 -8 15 3 44 8 65 12 23 4 37 12 37 21 0
13 11 14 73 7 96 -11 181 -6 214 12 29 14 53 18 53 8 0 -3 -5 -14 -11 -25 -9
-19 -9 -19 10 -10 11 6 29 11 41 11 13 0 19 4 14 11 -4 7 1 9 12 7 11 -2 18
-10 17 -16 -4 -18 83 -15 111 3 18 12 29 13 51 5 21 -8 30 -8 36 1 4 7 12 8
20 3 7 -4 42 -7 76 -6 43 2 63 -1 63 -9 0 -9 23 -10 84 -6 74 5 87 4 107 -14
25 -21 59 -25 77 -7 12 12 210 14 221 2 3 -3 -1 -10 -9 -15 -10 -6 20 -8 90
-5 58 3 122 5 143 5 30 1 37 5 37 21 0 15 7 20 25 20 14 0 25 -4 25 -9 0 -10
70 -7 127 5 21 4 47 0 73 -10 49 -19 56 -20 41 -3 -6 8 -8 17 -4 20 10 10 103
8 103 -2 0 -4 11 -6 25 -3 14 2 22 8 19 13 -3 5 1 9 9 9 10 0 13 -7 10 -19 -5
-21 -9 -21 167 0 47 5 182 12 300 14 295 4 344 10 381 47 49 50 36 85 -36 99
-30 6 -62 5 -84 -1 -53 -15 -72 -12 -78 10 -3 11 -10 20 -15 20 -5 0 -6 5 -2
11 5 8 -3 10 -27 6 -88 -16 -139 -19 -152 -9 -24 21 -507 43 -507 23 0 -5 -41
-13 -90 -17 -52 -4 -94 -12 -98 -19 -13 -21 -36 -23 -132 -10 -139 18 -145 22
-169 99 -11 36 -33 98 -50 138 -57 142 -91 238 -91 258 0 12 -4 19 -9 15 -6
-3 -9 3 -8 14 1 23 -56 150 -94 206 -14 22 -47 78 -72 125 -88 167 -125 225
-140 222 -10 -2 -14 3 -10 16 2 10 -13 47 -36 85 -23 37 -41 69 -41 72 0 22
-166 253 -234 325 -37 40 -140 163 -246 295 -19 24 -41 42 -48 40 -7 -3 -10 0
-6 6 3 6 -6 24 -21 39 -22 23 -32 27 -46 19 -13 -6 -19 -6 -19 1 0 5 5 10 11
10 6 0 4 7 -4 15 -9 8 -17 20 -19 26 -3 9 -10 9 -27 0 -17 -9 -26 -10 -34 -2
-8 8 -4 11 16 11 15 0 27 3 27 7 0 19 -173 167 -354 304 -93 70 -105 79 -269
187 -103 68 -157 110 -161 125 -3 13 -26 33 -58 51 -105 57 -394 202 -448 226
-30 13 -87 38 -127 57 -40 18 -76 33 -80 33 -4 0 -48 15 -98 34 -122 47 -258
85 -339 97 -82 11 -177 44 -193 66 -10 13 -15 14 -28 3 -8 -7 -15 -9 -15 -5 0
4 -7 2 -15 -5 -13 -11 -13 -15 -3 -21 18 -12 30 -11 23 1 -3 6 -1 10 4 10 6 0
11 -4 11 -10 0 -5 19 -14 43 -20 23 -7 49 -20 57 -29 8 -10 48 -31 88 -46 39
-15 72 -31 72 -36 0 -14 -102 -11 -156 6 -26 8 -60 12 -74 9 -19 -4 -22 -3
-10 5 12 8 7 11 -22 11 -20 0 -39 4 -42 9 -9 14 -54 19 -67 7 -9 -8 -10 -8 -5
1 4 8 -7 16 -31 22 -50 14 -70 14 -76 -1 -3 -8 -6 -6 -6 5 -1 10 -9 17 -20 17
-11 0 -26 2 -34 5 -9 4 -17 -5 -24 -27 l-9 -33 -6 27 c-3 18 -1 25 6 21 6 -3
4 1 -4 9 -30 30 -104 49 -224 58 -165 12 -327 40 -349 61 -17 15 -15 17 28 29
81 23 401 1 580 -40 34 -8 51 -8 53 -1 5 14 32 14 32 1 0 -5 -6 -10 -12 -11
-7 0 7 -7 32 -14 25 -7 61 -20 80 -28 33 -15 36 -14 63 8 l27 24 -37 10 c-21
6 -49 11 -64 11 -14 0 -30 5 -35 10 -12 12 -106 16 -122 6 -6 -4 -39 5 -72 19
-39 16 -102 31 -173 40 -115 14 -284 24 -291 18z m-691 -33 c3 -5 1 -10 -4
-10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m-328 -117 c-3 -10 -5
-4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z m323 33 c0 -3 -4 -8 -10 -11 -5 -3
-10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m-620 -51 c13 -14 21 -25 18
-25 -2 0 -15 11 -28 25 -13 14 -21 25 -18 25 2 0 15 -11 28 -25z m215 -15
c-12 -5 -14 -9 -6 -9 8 -1 12 -4 8 -7 -9 -9 -28 6 -22 17 4 5 14 9 23 8 14 0
13 -2 -3 -9z m62 -9 c0 -11 -5 -18 -9 -15 -4 3 -5 11 -1 19 7 21 12 19 10 -4z
m660 -43 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m-697 1 c0 -5
-8 -9 -17 -8 -11 0 -13 3 -5 6 6 2 9 10 6 15 -4 7 -2 8 4 4 7 -4 12 -11 12
-17z m-154 -21 l29 -33 -32 29 c-31 28 -38 36 -30 36 2 0 16 -15 33 -32z
m1744 -24 c0 -8 -4 -12 -10 -9 -5 3 -10 10 -10 16 0 5 5 9 10 9 6 0 10 -7 10
-16z m-1643 -16 c2 -17 0 -19 -7 -8 -5 8 -6 18 -4 22 8 12 10 10 11 -14z
m1743 -1 c0 -2 -10 -12 -22 -23 l-23 -19 19 23 c18 21 26 27 26 19z m-1993
-39 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m223 7 c15 -14 18
-14 30 0 14 17 30 20 30 4 0 -5 -4 -7 -10 -4 -6 4 -10 -6 -10 -21 l0 -28 -28
27 c-16 15 -31 24 -35 20 -4 -3 -7 -1 -7 5 0 16 10 15 30 -3z m597 -7 c-3 -8
-6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m1931 2 c-7 -11 -15 -15 -17 -9
-2 6 3 15 10 20 20 12 21 11 7 -11z m-2430 -18 c2 -7 -7 -12 -22 -12 -14 0
-26 5 -26 11 0 6 7 8 16 5 9 -4 13 -2 8 5 -4 7 -1 10 6 7 8 -2 16 -10 18 -16z
m1582 8 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11
-10z m-1225 -10 c8 -6 61 -6 156 1 92 7 208 8 324 3 99 -4 223 -8 275 -9 82 0
355 -42 406 -62 9 -3 29 7 47 23 32 29 32 29 8 2 -14 -16 -23 -29 -20 -31 6
-4 122 -34 259 -68 123 -30 284 -83 364 -119 79 -36 200 -77 214 -73 6 3 12 0
12 -5 0 -5 23 -19 50 -31 28 -12 72 -35 97 -51 26 -17 53 -30 60 -30 39 0 128
-46 170 -88 58 -57 81 -72 198 -132 113 -58 193 -113 271 -187 33 -32 56 -55
50 -50 -5 4 -21 7 -35 7 -17 0 -22 3 -13 8 8 6 -25 34 -105 89 -65 44 -136 90
-158 102 -22 12 -62 36 -90 53 -79 49 -144 80 -155 73 -6 -3 -10 0 -10 7 0 13
-27 27 -220 116 -166 75 -210 93 -220 87 -5 -3 -10 0 -12 5 -3 10 -195 81
-268 99 -19 5 -75 21 -125 35 -49 15 -110 31 -135 36 -25 5 -94 21 -155 34
-76 18 -111 22 -116 14 -5 -7 -15 -6 -35 5 -33 18 -196 42 -388 57 -131 10
-519 14 -528 5 -2 -3 -60 -7 -129 -10 -68 -2 -125 -9 -127 -14 -2 -5 -12 -7
-23 -4 -11 3 -52 0 -92 -6 -40 -6 -90 -14 -112 -16 -91 -11 -115 -17 -115 -31
0 -17 -17 -19 -22 -3 -3 8 -29 5 -88 -9 -47 -11 -109 -25 -138 -31 -52 -11
-82 -29 -82 -50 0 -6 -4 -11 -10 -11 -5 0 -10 7 -10 16 0 12 -6 15 -22 9 -13
-3 -38 -10 -57 -15 -19 -5 -37 -14 -41 -21 -4 -6 -13 -8 -19 -5 -24 16 -353
-110 -583 -222 -71 -34 -98 -52 -98 -66 0 -14 -5 -17 -21 -13 -29 8 -86 -21
-93 -47 -5 -16 -8 -18 -15 -8 -13 21 -137 -57 -134 -84 1 -10 0 -13 -3 -6 -9
20 -26 14 -84 -28 -30 -22 -70 -50 -88 -62 -17 -13 -42 -30 -53 -38 -12 -8
-32 -25 -45 -37 -13 -12 -24 -20 -24 -17 0 8 -58 -34 -147 -109 -46 -37 -83
-75 -83 -84 0 -9 -6 -13 -14 -10 -15 6 -35 -11 -200 -172 -204 -197 -487 -535
-471 -561 4 -6 1 -9 -5 -8 -15 4 -143 -183 -234 -342 -135 -234 -252 -493
-347 -764 l-14 -39 -72 -4 c-48 -3 -73 -1 -73 6 0 14 95 272 121 329 11 24 16
48 13 54 -5 7 -2 8 6 3 10 -6 12 0 8 23 -3 23 0 33 11 37 9 3 48 69 86 147 39
79 91 180 118 225 26 45 47 86 46 90 -3 19 2 34 10 29 7 -4 51 60 89 128 3 5
-2 7 -11 3 -10 -3 -17 -2 -17 3 0 6 8 10 18 10 11 0 31 19 50 48 17 26 63 86
104 134 40 47 139 164 219 260 80 95 153 179 162 187 9 7 14 18 10 24 -3 6 -1
7 5 3 14 -8 142 111 142 131 0 8 4 12 8 9 5 -3 33 18 63 45 30 27 73 64 96 82
23 18 46 46 52 62 7 19 9 21 7 7 -4 -22 -3 -23 17 -9 12 8 21 23 19 33 -2 15
-1 16 6 5 8 -11 22 -5 68 29 136 99 154 115 155 135 0 11 3 14 6 7 3 -8 23 1
62 28 31 22 76 50 99 63 23 12 88 49 144 81 115 66 315 167 426 215 157 69
473 175 632 212 192 46 305 69 332 69 15 0 28 3 28 8 0 4 26 8 58 10 31 1 93
11 137 21 91 20 192 26 210 11z m-708 -32 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13
3 -3 4 -12 1 -19z m2759 -21 c8 3 14 -1 14 -10 0 -9 5 -19 12 -23 7 -4 8 -3 4
4 -5 8 -2 10 8 6 9 -3 16 -13 16 -21 0 -10 -6 -13 -16 -9 -9 3 -20 6 -25 6 -5
0 -10 10 -10 23 l-2 22 -4 -22 c-2 -13 -8 -23 -13 -23 -5 0 -7 6 -4 13 3 8 0
25 -5 39 -5 14 -7 29 -3 33 4 4 8 -5 10 -18 2 -18 8 -24 18 -20z m-269 -19
c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m-2687 -38 c0 -13 -11
-13 -30 0 -12 8 -11 10 8 10 12 0 22 -4 22 -10z m30 0 c0 -5 -5 -10 -11 -10
-5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m622 -53 c3 4 3 2 1 -5 -5
-20 138 -6 153 14 8 10 13 11 16 4 3 -9 31 -9 113 -1 158 16 566 14 736 -4
374 -39 690 -107 984 -211 139 -49 171 -56 167 -36 -1 6 2 12 8 12 5 0 10 -11
10 -25 0 -14 4 -25 9 -25 5 0 16 -2 23 -5 9 -4 19 4 26 17 11 23 11 23 6 -3
-5 -24 1 -28 83 -64 48 -22 130 -61 181 -87 52 -27 98 -45 103 -42 5 3 9 1 9
-4 0 -10 87 -65 155 -98 22 -10 42 -22 45 -25 3 -3 46 -32 95 -65 50 -33 107
-72 128 -87 21 -14 45 -23 53 -20 9 3 12 0 9 -8 -3 -9 17 -29 50 -53 30 -22
63 -47 72 -55 10 -9 45 -38 78 -63 33 -26 80 -65 104 -88 45 -41 65 -42 55 -3
-4 15 -11 20 -22 15 -14 -5 -15 -4 -2 10 11 12 20 13 33 6 39 -21 24 1 -39 55
-36 31 -61 57 -56 57 14 0 122 -89 122 -101 0 -5 7 -9 15 -9 9 0 15 -10 15
-26 0 -14 -4 -23 -10 -19 -5 3 -10 12 -10 20 0 8 -7 11 -20 8 -21 -5 -29 -25
-9 -20 6 1 14 -4 16 -10 3 -7 0 -13 -6 -13 -6 0 -11 -7 -11 -15 0 -8 5 -15 10
-15 6 0 10 -5 10 -11 0 -6 38 -49 85 -95 46 -46 84 -79 82 -75 -1 5 3 10 8 10
6 1 53 43 105 94 57 55 68 64 27 21 -37 -38 -64 -74 -60 -80 5 -5 4 -6 -1 -1
-10 7 -66 -40 -66 -56 0 -4 16 -25 35 -45 25 -26 34 -31 29 -17 -5 16 -4 17 5
5 6 -8 11 -22 11 -31 0 -9 9 -24 20 -34 11 -10 20 -14 20 -9 0 5 8 -6 18 -23
9 -18 31 -47 47 -65 45 -49 192 -248 272 -368 80 -120 179 -292 248 -430 26
-52 52 -101 57 -108 6 -7 23 -47 38 -90 15 -42 35 -92 44 -110 10 -19 18 -60
19 -100 1 -37 7 -71 14 -77 7 -6 9 -22 6 -38 -5 -27 -8 -29 -47 -24 -22 3
-101 -4 -174 -16 -111 -17 -135 -18 -142 -7 -7 11 -14 11 -38 1 -37 -15 -88
-11 -84 7 2 10 -7 11 -35 6 -34 -7 -171 -9 -379 -5 l-72 1 -26 53 c-85 168
-196 354 -223 370 -7 4 -13 18 -13 30 0 22 -37 81 -102 164 -114 145 -293 345
-372 416 -127 113 -196 167 -196 152 0 -4 -11 7 -25 23 -22 26 -109 88 -234
166 -24 15 -45 35 -48 44 -2 10 -14 22 -26 28 -12 7 -69 37 -127 68 -58 31
-136 71 -175 87 -164 72 -223 96 -280 113 -33 10 -85 26 -115 35 -63 21 -111
22 -118 3 -2 -7 11 -19 31 -27 32 -13 58 -45 37 -45 -6 0 -10 4 -10 8 0 5 -15
9 -32 10 -28 1 -205 40 -388 87 -137 35 -137 37 -19 -80 104 -104 106 -106
180 -125 44 -12 75 -15 77 -10 5 13 23 13 20 -1 -2 -5 33 -23 77 -39 200 -72
369 -139 383 -154 15 -15 13 -16 -18 -17 -19 -1 -27 -3 -17 -6 9 -2 17 -8 17
-13 0 -4 9 -6 20 -3 11 3 20 9 20 14 0 5 12 9 26 9 14 0 23 -4 20 -9 -8 -12
65 -31 94 -25 18 4 21 3 11 -4 -21 -15 13 -54 76 -89 42 -22 51 -32 47 -47 -5
-16 -2 -18 27 -13 28 4 44 -3 94 -39 33 -24 89 -70 125 -103 36 -34 71 -59 78
-56 7 3 11 1 8 -4 -6 -9 32 -38 55 -43 23 -5 58 -52 46 -60 -8 -5 -6 -8 6 -8
9 0 17 6 17 14 0 8 4 17 9 21 5 3 8 -7 6 -23 -3 -25 5 -36 51 -76 32 -28 54
-55 54 -67 0 -11 5 -17 10 -14 12 7 55 -41 82 -91 23 -44 23 -44 -9 -44 -49 0
-103 -12 -97 -21 3 -5 41 -6 85 -3 56 5 79 4 79 -5 0 -6 -12 -11 -26 -11 -14
0 -23 -4 -20 -8 3 -5 18 -7 33 -5 24 4 33 -2 61 -41 18 -25 30 -46 25 -46 -4
-1 -48 -7 -98 -16 -49 -8 -96 -15 -102 -16 -7 -1 -13 -5 -13 -10 0 -11 37 -9
141 6 77 10 89 10 107 -4 23 -19 46 -59 38 -67 -3 -3 2 -12 12 -19 14 -11 15
-15 3 -23 -16 -11 -6 -32 12 -25 14 5 50 -47 109 -156 53 -100 53 -96 -2 -96
-28 0 -46 4 -43 9 3 6 -37 8 -100 5 -58 -2 -103 0 -101 4 3 5 1 8 -4 8 -5 0
-23 3 -40 6 -23 5 -35 1 -52 -16 -20 -20 -33 -22 -233 -26 -117 -3 -224 -10
-238 -15 -14 -6 -30 -9 -35 -7 -5 1 -39 -1 -77 -5 -71 -8 -130 5 -182 39 -29
19 -84 20 -239 4 -61 -6 -124 -9 -141 -7 -16 3 -34 6 -40 7 -33 5 48 20 139
26 58 4 106 11 106 16 0 9 -77 7 -190 -6 -25 -3 -90 -5 -145 -6 -55 -1 -128
-8 -163 -15 -35 -8 -76 -15 -91 -15 -15 0 -32 -5 -37 -10 -8 -8 -36 -8 -90 -1
-77 9 -119 9 -389 -5 -192 -10 -275 -5 -275 15 0 5 12 8 28 8 131 1 313 24
467 59 99 23 154 44 115 44 -27 0 -258 -48 -271 -56 -7 -4 -35 -8 -63 -9 -28
-1 -96 -7 -151 -14 -55 -6 -137 -14 -182 -18 -46 -3 -83 -9 -83 -12 0 -3 -69
-5 -152 -3 -140 2 -229 8 -405 28 -45 5 -63 3 -63 -5 0 -6 -11 -11 -25 -11
-14 0 -25 5 -25 10 0 6 -9 10 -21 10 -11 0 -18 -4 -14 -9 10 -17 -86 -24 -220
-16 -172 10 -205 8 -235 -13 -45 -32 -116 -43 -210 -31 -47 6 -148 14 -225 17
-101 5 -132 9 -110 16 17 4 38 10 48 13 26 7 20 21 -8 19 -14 0 -25 -4 -25 -8
0 -5 -24 -8 -52 -8 -38 0 -49 -3 -38 -10 13 -9 13 -10 0 -11 -52 -5 -117 5
-140 23 -21 15 -35 18 -63 13 -20 -4 -35 -10 -33 -14 2 -3 -43 -4 -100 -3 -59
2 -104 0 -104 -5 0 -5 -15 -10 -32 -10 -18 0 -46 -4 -62 -8 -25 -7 -28 -5 -23
9 7 18 -5 21 -31 7 -9 -4 -33 -11 -54 -15 -36 -7 -36 -6 -35 29 1 20 4 30 9
22 5 -8 11 -1 19 21 10 30 14 32 60 32 27 0 49 4 49 9 0 5 -18 8 -40 6 -50 -5
-50 2 -1 80 23 37 47 62 62 66 17 5 19 7 6 8 -17 1 -16 5 8 49 56 96 69 109
127 116 29 4 80 9 113 12 82 5 205 25 205 33 0 3 19 6 43 6 52 1 97 12 97 25
0 6 -9 6 -26 0 -14 -6 -48 -10 -75 -10 -27 0 -49 -4 -49 -10 0 -5 -24 -10 -54
-10 -67 0 -147 -11 -142 -19 2 -3 -27 -6 -65 -6 -38 0 -68 4 -66 9 14 42 422
474 462 490 8 3 15 13 15 22 0 10 3 14 6 11 4 -3 16 0 28 8 11 8 30 15 41 15
11 0 46 9 79 20 32 11 63 17 68 14 13 -8 58 4 58 16 0 6 -9 10 -21 10 -12 0
-17 -4 -13 -11 4 -7 0 -9 -14 -5 -19 6 -77 -3 -114 -19 -31 -13 -19 9 19 35
20 14 47 34 59 45 12 11 38 33 58 49 20 15 33 33 29 39 -4 7 0 8 12 3 15 -5
16 -4 5 8 -6 9 -10 21 -7 28 4 9 6 7 6 -4 1 -9 7 -18 14 -21 22 -8 34 4 29 29
-2 13 0 24 7 24 5 0 8 -6 5 -14 -9 -22 9 -26 90 -19 85 7 101 19 29 22 -26 1
-48 3 -50 5 -2 1 54 32 123 67 75 38 129 71 132 82 4 18 22 24 22 8 0 -18 41
2 55 28 10 19 14 21 15 9 0 -20 15 -24 25 -8 3 6 2 10 -3 10 -10 0 -25 30 -19
37 10 9 37 -11 37 -27 0 -17 4 -17 58 5 122 50 419 128 428 113 7 -11 54 -10
54 1 0 30 200 61 393 61 108 0 146 -3 141 -11 -5 -9 4 -9 32 -3 69 15 490 10
504 -7 6 -7 105 -39 121 -39 7 0 -34 47 -91 105 -96 97 -107 105 -142 105 -33
0 -38 -3 -39 -22 -1 -21 -1 -21 -10 4 -9 23 -18 28 -57 33 -133 17 -112 45 34
45 81 0 103 3 108 15 4 12 -4 15 -42 16 -26 0 -69 1 -97 2 -119 2 -478 -6
-565 -14 -233 -21 -469 -68 -665 -131 -104 -33 -206 -69 -225 -78 -19 -10 -64
-28 -99 -41 -40 -15 -66 -31 -69 -42 -3 -13 -7 -15 -12 -6 -6 9 -30 0 -91 -33
-46 -25 -97 -48 -113 -52 -16 -4 -55 -27 -86 -50 -31 -24 -78 -56 -106 -71
-157 -90 -344 -234 -492 -379 -150 -147 -208 -214 -193 -229 16 -16 14 -24
-10 -30 -18 -5 -20 -2 -15 16 5 21 4 21 -9 4 -12 -17 -14 -17 -22 -2 -8 13
-21 0 -67 -69 -32 -47 -61 -86 -66 -86 -16 0 -81 -106 -74 -123 4 -11 1 -17
-7 -17 -32 -1 -170 -224 -155 -249 3 -5 0 -11 -7 -14 -7 -3 -35 -50 -62 -104
l-48 -100 -136 -5 c-75 -3 -165 -3 -201 1 -50 6 -67 5 -73 -6 -10 -16 -57 -17
-92 -1 -21 10 -28 9 -35 -2 -7 -11 -24 -10 -107 5 -63 11 -138 17 -211 16 -84
-2 -112 1 -107 10 4 6 2 14 -4 18 -12 8 16 100 63 205 15 32 25 65 22 73 -3 8
-2 11 3 8 5 -3 13 5 17 17 22 71 225 459 278 533 43 61 96 153 96 170 0 9 4
14 8 11 4 -2 32 29 62 70 94 128 188 245 274 339 145 158 142 152 120 178
l-19 22 22 -19 23 -19 91 88 c51 48 113 104 138 124 25 20 69 57 96 83 55 50
131 110 163 127 19 10 23 35 9 59 -3 4 2 13 10 21 13 12 14 11 8 -8 -4 -13 -2
-21 5 -21 6 0 8 -5 4 -11 -8 -13 95 51 146 90 19 15 88 59 153 97 111 65 118
71 113 96 -6 31 8 47 18 21 6 -14 9 -15 16 -4 4 8 6 20 3 27 -2 6 1 15 8 20 7
4 10 3 5 -4 -3 -6 -2 -13 4 -17 5 -3 10 -11 10 -16 0 -6 -4 -8 -8 -5 -4 2 -13
-4 -20 -15 -16 -25 -12 -24 50 11 29 16 79 42 110 56 44 20 56 30 53 45 -2 11
0 17 6 14 5 -4 7 -11 4 -16 -4 -5 -4 -9 -2 -9 8 0 162 63 172 70 33 25 344
135 465 165 329 83 312 77 307 104 -5 25 -5 25 10 6 14 -20 14 -20 30 0 13 16
15 17 10 3 -8 -22 6 -23 103 -6 68 12 86 23 55 34 -8 4 -15 11 -15 17 0 5 9 2
19 -7 10 -10 21 -14 23 -9z m1363 3 c3 -5 -1 -10 -10 -10 -9 0 -13 5 -10 10 3
6 8 10 10 10 2 0 7 -4 10 -10z m960 -8 c-3 -3 -11 0 -18 7 -9 10 -8 11 6 5 10
-3 15 -9 12 -12z m112 -44 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0
-25z m-397 7 c0 -9 -6 -12 -16 -8 -13 5 -13 7 -2 14 17 11 18 11 18 -6z
m-3580 -19 c0 -2 -9 -6 -20 -9 -11 -3 -20 -1 -20 4 0 5 9 9 20 9 11 0 20 -2
20 -4z m0 -26 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4
4 -10z m4001 -17 c-8 -15 -10 -15 -11 -2 0 17 10 32 18 25 2 -3 -1 -13 -7 -23z
m286 -5 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-4268 -95 c0
-5 -4 -17 -9 -28 -8 -18 -9 -17 -9 8 -1 15 4 27 9 27 6 0 10 -3 9 -7z m93 -15
c-12 -12 -35 -1 -27 12 3 5 13 6 21 3 10 -4 12 -9 6 -15z m3938 -13 c0 -8 -2
-15 -4 -15 -2 0 -6 7 -10 15 -3 8 -1 15 4 15 6 0 10 -7 10 -15z m-3968 -37
c-7 -7 -12 -8 -12 -2 0 14 12 26 19 19 2 -3 -1 -11 -7 -17z m-427 2 c-3 -5
-11 -10 -16 -10 -6 0 -7 5 -4 10 3 6 11 10 16 10 6 0 7 -4 4 -10z m4388 -87
c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m933 -98 c-22 -17 -36
-19 -36 -7 0 14 12 22 34 22 21 -1 21 -1 2 -15z m1 -101 c-3 -3 -12 -4 -19 -1
-8 3 -5 6 6 6 11 1 17 -2 13 -5z m13 -33 c0 -6 -4 -13 -10 -16 -5 -3 -10 1
-10 9 0 9 5 16 10 16 6 0 10 -4 10 -9z m-5825 -20 c-3 -6 -11 -11 -16 -11 -5
0 -4 6 3 14 14 16 24 13 13 -3z m5820 -61 c-3 -5 -12 -10 -18 -10 -7 0 -6 4 3
10 19 12 23 12 15 0z m-5322 -109 c3 -13 0 -18 -7 -15 -7 3 -13 11 -13 19 0
22 14 18 20 -4z m5658 -154 c6 -10 -15 7 -46 36 -30 30 -55 58 -55 63 0 11 88
-75 101 -99z m139 40 c-10 -9 -11 -9 -6 0 3 6 2 13 -3 15 -4 1 -12 14 -16 28
-7 25 -7 25 15 -3 18 -23 20 -31 10 -40z m-35 24 c-9 -16 -25 -3 -18 15 4 10
9 12 15 6 6 -6 7 -15 3 -21z m-6262 -18 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5
14 0 19 -2 13 -5z m37 -3 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11
10 2 0 4 -4 4 -10z m-270 -105 c47 -47 82 -85 80 -85 -3 0 -43 38 -90 85 -47
47 -82 85 -80 85 3 0 43 -38 90 -85z m5967 63 c-3 -8 -6 -5 -6 6 -1 11 2 17 5
13 3 -3 4 -12 1 -19z m200 -45 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21
0 -30z m453 23 c0 -2 -8 -10 -17 -17 -16 -13 -17 -12 -4 4 13 16 21 21 21 13z
m-91 -84 c14 12 14 11 2 -5 -12 -15 -9 -22 30 -65 47 -51 59 -60 59 -43 0 6 5
11 11 11 6 0 8 -7 5 -16 -9 -22 34 -70 59 -66 19 3 19 2 2 -12 -15 -12 -16
-16 -5 -23 8 -4 41 -44 74 -87 32 -44 90 -120 127 -170 38 -50 93 -129 124
-176 30 -47 63 -96 72 -110 10 -14 30 -48 46 -77 15 -29 32 -50 38 -46 6 3 7
1 3 -6 -4 -6 8 -39 27 -74 19 -34 63 -117 96 -185 34 -67 66 -120 70 -118 4 3
8 -7 9 -23 0 -16 12 -55 25 -87 36 -85 145 -399 147 -421 0 -2 -23 -3 -51 -3
-40 0 -52 4 -55 18 -3 9 -14 35 -25 57 -20 42 -20 43 -2 -48 6 -29 5 -30 -23
-23 -40 10 -42 13 -65 97 -11 41 -33 108 -49 149 -16 41 -52 138 -80 215 -69
186 -325 692 -389 767 -6 7 -42 58 -80 113 -89 128 -156 218 -181 240 -33 29
-23 37 20 15 49 -25 50 -25 50 3 0 30 -25 67 -114 170 -42 48 -76 90 -76 92 0
3 19 -13 41 -35 35 -34 44 -38 58 -28z m-3549 55 c0 -2 -10 -12 -22 -23 l-23
-19 19 23 c18 21 26 27 26 19z m3600 -11 c0 -2 -8 -10 -17 -17 -16 -13 -17
-12 -4 4 13 16 21 21 21 13z m-510 -46 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10
-3 6 -1 10 4 10 6 0 11 -4 11 -10z m507 4 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6
11 1 17 -2 13 -5z m-3677 -27 c0 -2 -10 -12 -22 -23 l-23 -19 19 23 c18 21 26
27 26 19z m702 -36 c1 -23 5 -30 15 -27 7 3 13 1 13 -4 0 -6 -6 -10 -12 -10
-14 0 -48 20 -35 20 4 0 3 10 -4 21 -8 15 -8 19 0 15 6 -4 11 0 11 10 0 31 11
7 12 -25z m-295 -13 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z
m260 -10 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m2712 -31 c-2
-3 -15 10 -28 27 l-24 31 28 -27 c16 -15 27 -29 24 -31z m-2932 1 c-3 -8 -6
-5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-527 9 c0 -2 -10 -12 -22 -23
l-23 -19 19 23 c18 21 26 27 26 19z m4014 -43 c3 -8 2 -12 -4 -9 -6 3 -10 10
-10 16 0 14 7 11 14 -7z m-2657 -46 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4
-12 1 -19z m-2187 -8 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2
0 4 -4 4 -10z m2565 -30 c-2 -27 -15 -38 -15 -13 0 16 8 33 15 33 1 0 1 -9 0
-20z m1815 4 c0 -8 -5 -12 -10 -9 -6 4 -8 11 -5 16 9 14 15 11 15 -7z m-1870
-4 c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4 15 -10z
m-2436 -25 c-4 -8 -8 -15 -10 -15 -2 0 -4 7 -4 15 0 8 4 15 10 15 5 0 7 -7 4
-15z m60 -41 c9 -24 7 -28 -9 -14 -8 6 -20 8 -27 4 -10 -6 -9 -3 1 9 17 21 27
22 35 1z m4393 -26 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z
m-4519 -31 c7 -21 1 -27 -18 -17 -11 6 -20 14 -20 19 0 9 34 8 38 -2z m2897
-27 c-3 -5 -14 -10 -23 -10 -15 0 -15 2 -2 10 20 13 33 13 25 0z m-5515 -90
c0 -5 -7 -10 -16 -10 -8 0 -12 5 -9 10 3 6 10 10 16 10 5 0 9 -4 9 -10z m2277
-22 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-2267 -8 c0 -5 -7
-10 -16 -10 -8 0 -12 5 -9 10 3 6 10 10 16 10 5 0 9 -4 9 -10z m2177 -12 c-3
-8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m18 -28 c3 -5 1 -10 -4 -10
-6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m-2325 -30 c0 -5 -5 -10
-11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m149 -37 c0 -5 -4
-17 -9 -28 -8 -18 -9 -17 -9 8 -1 15 4 27 9 27 6 0 10 -3 9 -7z m5778 -15 c-3
-8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-5683 3 c10 -15 -22 -20
-34 -5 -10 11 -9 14 8 14 12 0 23 -4 26 -9z m5701 -31 c3 -5 2 -10 -4 -10 -5
0 -13 5 -16 10 -3 6 -2 10 4 10 5 0 13 -4 16 -10z m52 -27 c-3 -10 -5 -4 -5
12 0 17 2 24 5 18 2 -7 2 -21 0 -30z m-5782 7 c-3 -5 -12 -10 -18 -10 -7 0 -6
4 3 10 19 12 23 12 15 0z m325 -5 c0 -8 -4 -15 -10 -15 -5 0 -7 7 -4 15 4 8 8
15 10 15 2 0 4 -7 4 -15z m1497 -27 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2
-19 0 -25z m3918 21 c11 -16 1 -19 -13 -3 -7 8 -8 14 -3 14 5 0 13 -5 16 -11z
m-8 -51 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-3870 -10 c-3
-8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m3849 -38 c8 0 14 -4 14 -9
0 -5 9 -13 20 -16 25 -8 27 -38 3 -33 -10 2 -17 9 -16 14 1 6 -6 15 -16 21
-16 10 -17 8 -13 -13 2 -13 0 -24 -4 -24 -5 0 -10 21 -10 48 -1 26 0 39 3 30
2 -10 11 -18 19 -18z m-5701 -49 c-8 -13 -35 -15 -35 -2 0 5 8 11 18 13 21 5
26 2 17 -11z m7790 9 c-3 -5 -10 -10 -16 -10 -5 0 -9 5 -9 10 0 6 7 10 16 10
8 0 12 -4 9 -10z m-6175 -12 c0 -5 -12 -5 -27 -2 -40 10 -42 15 -5 12 18 -1
32 -6 32 -10z m6194 -23 c-4 -8 -10 -15 -15 -15 -5 0 -9 7 -9 15 0 8 7 15 15
15 9 0 12 -6 9 -15z m-1771 -40 c-3 -9 -8 -14 -10 -11 -3 3 -2 9 2 15 9 16 15
13 8 -4z m-103 -9 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0
10 -2 10 -4z m-103 -38 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z
m1978 12 c-3 -5 -11 -10 -16 -10 -6 0 -7 5 -4 10 3 6 11 10 16 10 6 0 7 -4 4
-10z m-8148 -57 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z m6199
-19 c-3 -9 -14 -14 -23 -12 -10 2 -16 7 -15 12 2 5 -2 13 -10 18 -8 5 -4 7 15
4 16 -3 27 0 28 7 0 8 2 8 6 -1 3 -7 2 -20 -1 -28z m254 -96 c0 -3 4 -8 8 -10
13 -5 165 -200 228 -293 32 -47 62 -85 66 -85 5 0 6 5 2 12 -4 6 -3 8 4 5 5
-4 8 -16 5 -26 -3 -10 1 -22 8 -26 8 -6 10 -4 4 6 -8 12 -6 12 8 0 18 -14 24
-36 7 -26 -17 11 -10 -8 29 -77 21 -38 41 -65 46 -60 4 4 5 2 1 -5 -3 -6 3
-32 15 -56 20 -41 21 -47 6 -61 -20 -20 -71 -21 -90 -1 -8 8 -18 11 -22 7 -5
-4 -5 -1 -1 6 12 21 -202 422 -226 422 -4 0 -8 4 -8 10 0 5 -14 29 -30 52 -17
24 -38 54 -47 68 -34 52 -77 90 -85 77 -5 -7 -8 1 -8 21 0 35 16 42 35 15 7
-9 17 -13 23 -9 15 9 -16 72 -53 106 -15 14 -32 34 -37 45 -6 11 17 -9 51 -45
33 -36 61 -68 61 -72z m34 107 c-4 -8 -8 -15 -10 -15 -2 0 -4 7 -4 15 0 8 4
15 10 15 5 0 7 -7 4 -15z m1371 -5 c-3 -5 -10 -10 -16 -10 -5 0 -9 5 -9 10 0
6 7 10 16 10 8 0 12 -4 9 -10z m-7927 -12 c8 -10 3 -13 -19 -14 -26 -1 -27 0
-11 12 9 7 18 14 18 14 1 0 6 -6 12 -12z m52 -18 c0 -11 -4 -20 -10 -20 -5 0
-10 9 -10 20 0 11 5 20 10 20 6 0 10 -9 10 -20z m427 -16 c-3 -3 -12 -4 -19
-1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-484 -29 c4 -8 2 -17 -3 -20 -6 -4 -10 3
-10 14 0 25 6 27 13 6z m6312 5 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2
10 4 10 3 0 8 -4 11 -10z m-6302 -113 c-3 -5 -1 -7 5 -3 6 3 13 2 17 -4 4 -6
-5 -10 -19 -10 -19 0 -26 5 -26 20 0 11 -3 20 -8 20 -4 0 -13 9 -20 21 -12 18
-8 17 22 -6 20 -15 33 -32 29 -38z m-2 46 c13 -16 12 -17 -3 -4 -10 7 -18 15
-18 17 0 8 8 3 21 -13z m1654 -42 c-6 -5 -25 10 -25 20 0 5 6 4 14 -3 8 -7 12
-15 11 -17z m42 13 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z
m-1727 -13 c0 -6 -4 -13 -10 -16 -5 -3 -10 1 -10 9 0 9 5 16 10 16 6 0 10 -4
10 -9z m1750 -16 c0 -8 -7 -15 -15 -15 -16 0 -20 12 -8 23 11 12 23 8 23 -8z
m6597 -47 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-8312 2 c3
-5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m-10 -28
c-3 -3 -11 0 -18 7 -9 10 -8 11 6 5 10 -3 15 -9 12 -12z m1725 -6 c0 -2 -7 -7
-16 -10 -8 -3 -12 -2 -9 4 6 10 25 14 25 6z m-1610 -21 c0 -8 -4 -15 -10 -15
-5 0 -7 7 -4 15 4 8 8 15 10 15 2 0 4 -7 4 -15z m6726 -62 c-3 -4 -17 -8 -29
-10 -17 -2 -19 -1 -7 7 17 11 47 14 36 3z m-2 -74 c-3 -5 -10 -7 -15 -3 -5 3
-7 10 -3 15 3 5 10 7 15 3 5 -3 7 -10 3 -15z m1694 -54 c-3 -3 -9 2 -12 12 -6
14 -5 15 5 6 7 -7 10 -15 7 -18z m-8668 -21 c0 -8 -4 -12 -10 -9 -5 3 -10 13
-10 21 0 8 5 12 10 9 6 -3 10 -13 10 -21z m7113 -194 c-3 -12 -8 -19 -11 -16
-5 6 5 36 12 36 2 0 2 -9 -1 -20z m-168 -80 c3 -5 1 -10 -4 -10 -6 0 -11 5
-11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m-5115 -165 c-34 -20 -90 -20 -90 0 0
12 13 15 58 15 54 -1 56 -1 32 -15z m5078 -4 c4 -11 -6 -13 -47 -9 -78 8 -93
30 -17 26 40 -2 61 -8 64 -17z m-5848 -13 c0 -13 -23 -5 -28 10 -2 7 2 10 12
6 9 -3 16 -11 16 -16z m6542 0 c-6 -6 -15 -8 -19 -4 -4 4 -1 11 7 16 19 12 27
3 12 -12z m-5729 -5 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z
m4920 0 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m-4990 -10 c-7
-2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m5070 0 c-7 -2 -19 -2 -25
0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m-3933 -13 c11 -7 6 -10 -20 -10 -26 0
-31 3 -20 10 8 5 17 10 20 10 3 0 12 -5 20 -10z"/>
<path d="M6686 8591 c-4 -5 13 -12 36 -16 100 -16 243 -47 253 -54 5 -5 40
-16 78 -26 37 -10 67 -21 67 -25 0 -5 7 -10 15 -14 10 -3 15 1 15 12 0 12 -13
23 -37 32 -21 7 -42 19 -48 26 -13 16 -155 45 -184 37 -11 -3 -43 4 -70 16
-51 22 -116 28 -125 12z"/>
<path d="M6060 8353 c0 -12 100 -14 118 -3 12 7 -2 9 -50 10 -38 0 -68 -3 -68
-7z"/>
<path d="M5935 8335 c-30 -13 -29 -13 25 -13 30 1 43 3 27 5 -15 2 -25 9 -22
14 8 11 7 11 -30 -6z"/>
<path d="M5850 8326 c0 -8 8 -13 18 -11 9 2 21 4 25 4 5 1 5 6 2 11 -9 15 -45
12 -45 -4z"/>
<path d="M5787 8318 c-7 -5 1 -8 21 -8 17 0 32 4 32 9 0 11 -35 10 -53 -1z"/>
<path d="M6696 8301 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 -4 11 -6 11 -12 0z"/>
<path d="M6720 8299 c0 -11 37 -12 43 -1 3 4 -6 8 -19 8 -13 1 -24 -2 -24 -7z"/>
<path d="M6659 8296 c-2 -2 -46 -9 -98 -15 -90 -10 -122 -24 -102 -44 12 -12
214 36 219 51 3 11 -10 17 -19 8z"/>
<path d="M6290 8220 c-30 -4 -82 -10 -115 -14 -33 -4 -67 -10 -75 -13 -8 -3
-42 -6 -75 -7 -123 -5 -254 -19 -249 -27 5 -9 227 -2 254 7 8 3 49 8 90 10 41
3 78 10 81 16 4 6 14 8 23 5 20 -8 126 9 140 23 11 11 12 11 -74 0z"/>
<path d="M7107 8203 c-25 -24 16 -31 47 -8 19 14 18 15 -10 15 -17 0 -34 -3
-37 -7z"/>
<path d="M7047 8193 c-14 -13 -6 -20 18 -15 14 2 22 8 19 13 -6 11 -28 12 -37
2z"/>
<path d="M6956 8175 c7 -17 22 -19 42 -6 10 6 8 10 -10 14 -34 10 -38 9 -32
-8z"/>
<path d="M5202 8165 c-11 -12 -10 -15 3 -15 8 0 15 7 15 15 0 18 -2 18 -18 0z"/>
<path d="M6850 8170 l-25 -8 28 -1 c15 -1 27 4 27 9 0 6 -1 10 -2 9 -2 -1 -14
-5 -28 -9z"/>
<path d="M5270 8160 c20 -13 33 -13 25 0 -3 6 -14 10 -23 10 -15 0 -15 -2 -2
-10z"/>
<path d="M5635 8160 l-50 -7 47 -1 c26 -1 50 3 53 8 3 6 5 9 3 8 -2 0 -25 -4
-53 -8z"/>
<path d="M6765 8153 c-11 -2 -45 -9 -75 -15 -46 -9 -51 -12 -30 -18 31 -8 122
12 128 28 2 7 2 11 0 11 -1 -1 -12 -3 -23 -6z"/>
<path d="M6563 8112 c-13 -2 -23 -10 -23 -19 0 -10 6 -13 16 -9 9 3 21 6 28 6
7 0 21 6 31 14 18 13 18 13 -6 13 -13 -1 -34 -3 -46 -5z"/>
<path d="M6453 8073 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M6315 8063 c-11 -2 -30 -6 -42 -9 -28 -6 -30 -24 -3 -24 11 0 20 5
20 10 0 6 10 8 24 4 28 -7 66 3 66 17 0 9 -28 10 -65 2z"/>
<path d="M7382 8060 c2 -6 7 -11 11 -11 4 1 17 1 29 1 27 0 21 5 -15 14 -20 5
-28 3 -25 -4z"/>
<path d="M7247 8054 c-13 -13 5 -25 19 -13 11 9 18 9 26 1 11 -11 38 -5 38 9
0 10 -73 12 -83 3z"/>
<path d="M7105 8040 c-27 -4 -45 -8 -40 -9 6 -1 3 -6 -5 -12 -11 -8 -8 -9 15
-5 17 2 51 8 78 11 26 3 47 10 47 16 0 10 -17 10 -95 -1z"/>
<path d="M6093 8033 c-18 -2 -33 -9 -33 -14 0 -11 50 -1 65 12 5 5 7 8 5 7 -3
0 -20 -3 -37 -5z"/>
<path d="M6140 8029 c0 -5 20 -9 45 -9 25 0 45 4 45 8 0 5 -20 9 -45 10 -25 0
-45 -4 -45 -9z"/>
<path d="M5984 8012 c5 -8 66 -4 66 4 0 2 -16 4 -36 4 -19 0 -33 -4 -30 -8z"/>
<path d="M6883 8013 c-25 -3 -44 -10 -47 -19 -5 -12 0 -14 22 -10 118 23 142
28 142 32 0 4 -47 3 -117 -3z"/>
<path d="M5068 8003 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5280 8000 c25 -4 52 -4 60 1 9 5 -8 8 -45 7 l-60 -1 45 -7z"/>
<path d="M5425 8000 c-4 -7 13 -8 52 -4 76 9 80 14 10 14 -31 0 -59 -5 -62
-10z"/>
<path d="M5610 8000 c0 -5 7 -7 15 -4 8 4 15 8 15 10 0 2 -7 4 -15 4 -8 0 -15
-4 -15 -10z"/>
<path d="M5710 7999 c0 -5 11 -9 25 -9 33 0 33 15 0 16 -14 1 -25 -2 -25 -7z"/>
<path d="M5796 8002 c-3 -5 17 -9 45 -11 29 -1 49 2 49 9 0 12 -86 14 -94 2z"/>
<path d="M6783 7993 c-20 -8 -15 -23 7 -23 11 0 20 7 20 15 0 15 -6 17 -27 8z"/>
<path d="M4880 7980 c-21 -7 -18 -8 18 -9 23 -1 42 3 42 7 0 10 -29 11 -60 2z"/>
<path d="M6565 7949 c-49 -11 -92 -24 -93 -29 -2 -6 -8 -8 -13 -4 -5 3 -9 0
-9 -5 0 -16 19 -13 37 5 9 8 19 11 22 6 3 -5 46 0 99 11 60 13 89 23 80 28
-17 11 -16 11 -123 -12z"/>
<path d="M7380 7900 c-19 -5 -38 -6 -41 -4 -12 7 -263 -17 -273 -26 -5 -5 -15
-6 -22 -3 -8 3 -14 0 -14 -7 0 -10 15 -11 56 -6 216 25 291 32 407 34 37 1 67
6 67 12 0 12 -129 12 -180 0z"/>
<path d="M7610 7899 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M7710 7899 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M6287 7893 c-16 -15 -5 -21 31 -16 61 9 73 22 21 23 -25 0 -49 -3
-52 -7z"/>
<path d="M6165 7880 c-22 -5 -84 -11 -137 -15 -65 -5 -98 -12 -98 -19 0 -9 31
-9 133 1 152 14 187 21 187 34 0 10 -28 10 -85 -1z"/>
<path d="M6970 7863 c-185 -28 -460 -91 -460 -104 0 -10 49 -5 105 11 47 13
213 47 290 60 105 17 115 19 115 30 0 10 -5 10 -50 3z"/>
<path d="M5403 7853 c-38 -14 0 -23 103 -23 76 0 113 3 109 10 -4 6 -40 10
-83 9 -42 -1 -86 0 -97 4 -11 3 -26 3 -32 0z"/>
<path d="M5885 7852 c-11 -11 -4 -22 15 -22 11 0 20 6 20 13 0 13 -25 19 -35
9z"/>
<path d="M5003 7841 c-58 -7 -82 -21 -36 -21 59 0 125 13 111 21 -7 5 -15 8
-18 7 -3 0 -28 -4 -57 -7z"/>
<path d="M5107 7839 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z"/>
<path d="M5640 7841 c0 -6 5 -13 10 -16 6 -3 10 1 10 9 0 9 -4 16 -10 16 -5 0
-10 -4 -10 -9z"/>
<path d="M5685 7834 c-20 -16 -19 -16 5 -10 14 3 58 6 98 6 40 0 71 4 68 9 -3
5 -38 9 -78 10 -53 0 -78 -4 -93 -15z"/>
<path d="M4845 7815 l-40 -14 45 6 c25 3 54 9 65 14 36 14 -25 9 -70 -6z"/>
<path d="M4740 7810 c-8 -5 -12 -12 -8 -15 10 -10 49 5 43 16 -8 11 -16 11
-35 -1z"/>
<path d="M4675 7802 c-16 -2 -34 -8 -40 -14 -7 -7 2 -9 29 -5 58 9 68 26 11
19z"/>
<path d="M4611 7784 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M6305 7739 c-203 -29 -205 -29 -205 -38 0 -4 8 -8 18 -9 34 -2 192
21 192 28 0 4 39 10 86 13 47 2 91 10 97 16 15 15 -18 13 -188 -10z"/>
<path d="M7410 7740 c0 -5 -13 -7 -31 -3 -37 7 -300 -18 -293 -28 2 -4 -6 -10
-18 -13 -23 -5 -4 -4 202 14 63 5 143 11 178 13 88 6 107 27 24 27 -34 0 -62
-4 -62 -10z"/>
<path d="M7545 7740 c3 -6 31 -10 62 -10 71 0 65 7 -10 14 -37 4 -56 2 -52 -4z"/>
<path d="M7690 7740 c37 -12 172 -12 165 0 -3 6 -46 10 -98 9 -61 -1 -84 -4
-67 -9z"/>
<path d="M7978 7742 c-32 -7 -19 -22 19 -22 20 0 34 1 32 3 -2 1 -11 7 -19 13
-8 6 -23 9 -32 6z"/>
<path d="M7870 7733 c0 -5 16 -8 35 -8 19 0 35 3 35 8 0 4 -16 7 -35 7 -19 0
-35 -3 -35 -7z"/>
<path d="M5285 7690 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M5940 7689 c0 -5 26 -9 57 -9 31 0 54 3 50 6 -10 10 -107 12 -107 3z"/>
<path d="M6900 7688 c-252 -51 -300 -62 -300 -69 0 -4 -15 -7 -32 -7 -18 1
-73 -7 -123 -17 -49 -9 -133 -23 -185 -29 -52 -7 -103 -16 -112 -20 -9 -4 -22
-5 -28 -1 -17 10 -171 -6 -163 -18 7 -11 194 -1 203 11 3 4 28 8 55 8 88 1
230 23 387 60 84 20 163 35 176 35 13 -1 21 3 18 8 -6 10 37 17 156 27 43 4
75 11 72 15 -6 11 -64 10 -124 -3z"/>
<path d="M5200 7680 c0 -5 14 -10 32 -10 36 0 35 4 -4 14 -17 4 -28 2 -28 -4z"/>
<path d="M5405 7681 c30 -12 345 -14 345 -2 0 6 -70 10 -182 10 -102 0 -174
-3 -163 -8z"/>
<path d="M5760 7681 c0 -5 30 -9 68 -9 61 1 63 1 27 9 -55 11 -95 11 -95 0z"/>
<path d="M5035 7670 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M4765 7640 c-4 -7 3 -8 22 -4 38 9 42 14 10 14 -14 0 -29 -5 -32 -10z"/>
<path d="M4620 7633 c-19 -3 -39 -9 -45 -15 -12 -13 99 -2 124 12 17 10 -8 11
-79 3z"/>
<path d="M4505 7613 c-11 -2 -37 -11 -58 -19 -20 -8 -52 -14 -70 -14 -17 0
-39 -5 -47 -10 -25 -16 53 -12 134 7 70 16 125 44 81 42 -11 -1 -29 -3 -40 -6z"/>
<path d="M7340 7578 c-98 -10 -135 -15 -135 -17 0 -1 -40 -3 -90 -6 -49 -2
-178 -22 -285 -44 -107 -22 -249 -51 -315 -64 -99 -21 -290 -48 -342 -50 -7 0
-13 -5 -13 -10 0 -13 194 0 198 13 2 5 9 7 16 4 21 -8 326 51 336 64 3 4 16 8
30 8 14 1 93 13 175 27 303 54 403 62 790 62 382 0 395 1 395 13 0 10 -658 11
-760 0z"/>
<path d="M8110 7582 c0 -4 28 -8 63 -9 43 -2 56 0 42 7 -25 11 -105 13 -105 2z"/>
<path d="M4260 7549 c0 -5 11 -9 25 -9 14 0 25 2 25 4 0 2 -11 6 -25 8 -14 3
-25 1 -25 -3z"/>
<path d="M5265 7530 c-30 -5 -33 -8 -13 -9 14 0 33 -4 41 -7 8 -3 17 2 20 10
4 9 3 15 -1 15 -4 -1 -25 -5 -47 -9z"/>
<path d="M5340 7530 c0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 -9 10 -20 10 -11
0 -20 -4 -20 -10z"/>
<path d="M5390 7526 c0 -10 8 -16 22 -16 12 0 19 1 17 3 -2 1 -12 9 -21 16
-15 12 -18 11 -18 -3z"/>
<path d="M5479 7527 c5 -5 44 -9 87 -9 43 1 73 -3 66 -7 -7 -4 7 -6 30 -5 24
0 97 2 162 3 195 2 123 14 -123 20 -127 3 -226 2 -222 -2z"/>
<path d="M5035 7523 c-96 -11 -115 -15 -115 -25 0 -7 17 -8 53 -3 28 5 74 9
101 9 30 1 51 6 53 14 5 12 -22 14 -92 5z"/>
<path d="M5185 7520 c28 -12 37 -12 30 0 -3 6 -16 10 -28 9 -21 0 -21 -1 -2
-9z"/>
<path d="M4790 7490 c-10 -6 3 -7 40 -3 30 3 57 7 59 9 10 9 -85 3 -99 -6z"/>
<path d="M4590 7461 c0 -5 -10 -7 -22 -3 -13 3 -19 1 -15 -6 4 -5 1 -13 -5
-16 -7 -3 1 -3 17 1 17 3 44 12 60 19 l30 12 -32 1 c-18 1 -33 -3 -33 -8z"/>
<path d="M4480 7443 c-14 -2 -36 -8 -50 -13 -14 -5 -42 -11 -63 -15 -21 -3
-35 -10 -32 -16 4 -5 -4 -9 -16 -9 -12 0 -20 -3 -16 -6 9 -9 200 38 215 53 15
14 13 14 -38 6z"/>
<path d="M8277 7433 c-10 -9 -9 -23 2 -23 5 0 11 7 15 15 5 15 -5 20 -17 8z"/>
<path d="M8340 7430 c0 -5 6 -10 14 -10 8 0 18 5 21 10 3 6 -3 10 -14 10 -12
0 -21 -4 -21 -10z"/>
<path d="M7433 7423 c-30 -2 -53 -9 -53 -15 0 -8 24 -9 77 -4 42 3 124 6 182
7 69 1 97 4 81 9 -25 8 -189 10 -287 3z"/>
<path d="M7795 7420 l-30 -6 34 -7 c48 -10 137 -9 126 2 -13 12 -93 19 -130
11z"/>
<path d="M8135 7422 c-12 -12 -4 -23 13 -18 9 3 38 6 64 7 26 1 45 6 42 10 -5
9 -111 9 -119 1z"/>
<path d="M7285 7411 c-19 -8 -19 -8 2 -15 24 -8 43 -1 43 15 0 11 -17 11 -45
0z"/>
<path d="M7975 7410 c-4 -6 5 -10 20 -10 15 0 24 4 20 10 -3 6 -12 10 -20 10
-8 0 -17 -4 -20 -10z"/>
<path d="M7155 7399 c-22 -4 -57 -8 -77 -8 -21 -1 -38 -5 -38 -10 0 -15 21
-18 69 -10 25 4 66 8 91 9 30 0 40 3 31 9 -11 7 -10 10 5 14 33 8 -38 5 -81
-4z"/>
<path d="M6060 7379 c0 -6 -6 -9 -12 -6 -7 2 -22 1 -33 -3 -12 -5 8 -7 45 -5
86 4 87 4 70 15 -21 13 -70 13 -70 -1z"/>
<path d="M4170 7370 c-12 -8 -11 -10 8 -10 12 0 22 5 22 10 0 13 -11 13 -30 0z"/>
<path d="M4215 7370 c-3 -5 3 -10 14 -10 12 0 21 5 21 10 0 6 -6 10 -14 10 -8
0 -18 -4 -21 -10z"/>
<path d="M5312 7371 c-11 -7 -11 -9 2 -14 10 -4 16 -1 16 8 0 17 -1 17 -18 6z"/>
<path d="M5347 7373 c-16 -16 -4 -23 38 -23 46 0 61 14 25 23 -11 3 -20 0 -20
-6 0 -8 -4 -7 -12 1 -14 14 -22 15 -31 5z"/>
<path d="M6979 7375 c-3 -2 -54 -12 -114 -21 -61 -9 -118 -23 -127 -32 -10 -8
-25 -12 -33 -8 -9 3 -53 -3 -98 -13 -45 -11 -102 -24 -127 -29 l-45 -10 45 -1
c46 -1 230 32 265 48 16 7 185 38 244 44 19 3 27 27 9 27 -8 0 -17 -2 -19 -5z"/>
<path d="M5065 7360 c-48 -8 -28 -21 25 -16 19 2 43 4 53 5 10 0 15 6 12 11
-7 11 -28 11 -90 0z"/>
<path d="M5190 7360 c20 -13 43 -13 35 0 -3 6 -16 10 -28 10 -18 0 -19 -2 -7
-10z"/>
<path d="M5460 7358 c0 -5 50 -8 110 -8 68 0 110 4 110 10 0 7 -40 9 -110 8
-60 -2 -110 -6 -110 -10z"/>
<path d="M4950 7340 c36 -12 60 -12 60 0 0 6 -19 10 -42 9 -37 -1 -39 -2 -18
-9z"/>
<path d="M4764 7316 c-15 -11 -13 -12 14 -8 36 5 38 7 18 15 -8 3 -22 0 -32
-7z"/>
<path d="M4605 7301 c-20 -8 -20 -8 1 -15 25 -8 56 2 48 15 -6 11 -21 11 -49
0z"/>
<path d="M8265 7271 c-18 -7 -17 -8 7 -14 29 -8 58 0 58 14 0 11 -37 11 -65 0z"/>
<path d="M8345 7270 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M4403 7263 c-24 -5 -30 -18 -14 -28 5 -3 16 1 24 9 8 8 17 13 20 10
3 -3 8 -1 12 5 6 11 -2 12 -42 4z"/>
<path d="M7413 7263 c-18 -2 -33 -9 -33 -15 0 -7 51 -8 163 -3 89 5 177 7 195
6 17 -1 32 3 32 9 0 10 -282 12 -357 3z"/>
<path d="M7806 7255 c5 -12 25 -15 90 -15 68 0 84 3 84 15 0 12 -17 15 -90 15
-75 0 -88 -2 -84 -15z"/>
<path d="M7997 7263 c-13 -12 -7 -23 13 -23 11 0 20 7 20 15 0 15 -21 21 -33
8z"/>
<path d="M8193 7263 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M7333 7243 c9 -9 19 -14 23 -11 10 10 -6 28 -24 28 -15 0 -15 -1 1
-17z"/>
<path d="M4330 7242 c0 -4 9 -8 19 -8 11 0 17 4 15 8 -3 4 -12 8 -20 8 -8 0
-14 -4 -14 -8z"/>
<path d="M6240 7241 c-25 -3 -44 -9 -42 -13 1 -5 4 -8 7 -8 3 0 20 0 39 0 21
0 36 6 40 15 3 8 4 14 3 13 -1 0 -22 -4 -47 -7z"/>
<path d="M7135 7234 l-80 -13 100 5 c137 6 155 8 155 16 0 11 -82 7 -175 -8z"/>
<path d="M6074 7219 c-2 -4 -22 -5 -45 -2 -29 4 -44 2 -50 -8 -5 -8 -9 -9 -9
-4 0 7 -132 10 -378 9 -367 0 -552 -9 -552 -24 0 -12 463 1 478 13 10 8 16 7
22 -3 6 -10 59 -12 276 -7 148 3 278 8 289 11 11 2 28 5 38 5 9 1 17 4 17 9 0
9 -80 10 -86 1z"/>
<path d="M6990 7212 c0 -4 -23 -9 -51 -10 -28 -1 -88 -11 -132 -22 -181 -43
-428 -87 -535 -95 -80 -7 -90 -9 -70 -19 23 -12 66 -8 276 23 83 13 212 40
226 49 6 3 19 7 30 8 63 7 106 18 106 26 0 6 6 8 14 5 15 -6 151 15 161 24 3
4 -1 9 -10 12 -8 4 -15 3 -15 -1z"/>
<path d="M4906 7186 c-115 -16 -110 -34 6 -24 106 10 122 14 113 28 -7 12 -13
11 -119 -4z"/>
<path d="M4750 7155 c-9 -11 -10 -15 -1 -15 7 0 19 7 27 15 13 13 13 15 1 15
-8 0 -20 -7 -27 -15z"/>
<path d="M4630 7139 c0 -5 -4 -7 -10 -4 -5 3 -10 1 -10 -4 0 -16 29 -13 46 4
14 13 13 15 -5 15 -12 0 -21 -5 -21 -11z"/>
<path d="M4526 7115 c-6 -17 -2 -18 32 -8 18 4 20 8 10 14 -20 13 -35 11 -42
-6z"/>
<path d="M8340 7104 c0 -8 5 -12 10 -9 6 3 10 10 10 16 0 5 -4 9 -10 9 -5 0
-10 -7 -10 -16z"/>
<path d="M4450 7100 c-34 -8 -12 -18 25 -10 19 3 25 8 15 11 -8 3 -26 3 -40
-1z"/>
<path d="M7448 7100 c-15 -5 -29 -6 -31 -4 -12 12 -368 -29 -517 -61 -36 -7
-93 -19 -127 -25 -36 -7 -60 -17 -57 -22 4 -6 -2 -8 -13 -4 -11 4 -49 0 -85
-9 -79 -18 -331 -56 -376 -57 -17 0 -35 -4 -38 -9 -3 -5 15 -9 39 -9 71 0 167
12 162 20 -5 8 82 24 101 19 29 -8 294 47 294 61 0 5 15 8 33 7 17 0 91 9 162
21 225 36 481 62 623 62 56 0 92 4 92 10 0 12 -219 11 -262 0z"/>
<path d="M7750 7101 c0 -9 209 -22 224 -13 4 2 2 8 -5 13 -18 11 -219 12 -219
0z"/>
<path d="M8190 7099 c0 -5 11 -9 25 -9 14 0 25 2 25 4 0 2 -11 6 -25 8 -14 3
-25 1 -25 -3z"/>
<path d="M8258 7103 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M8305 7100 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M4345 7082 c-2 -3 -2 -8 1 -13 5 -9 34 -1 34 10 0 8 -27 10 -35 3z"/>
<path d="M5990 7058 c-58 -5 -228 -6 -377 -3 -278 6 -447 0 -437 -17 7 -11
938 -4 972 7 12 4 22 11 22 16 0 10 -33 10 -180 -3z"/>
<path d="M4161 7049 c-64 -12 -62 -19 5 -19 39 0 54 3 45 9 -11 7 -9 10 10 14
17 3 18 4 4 5 -11 0 -40 -4 -64 -9z"/>
<path d="M4068 7033 c-10 -3 -18 -9 -18 -14 0 -10 7 -11 34 0 23 9 9 19 -16
14z"/>
<path d="M5015 7034 c-5 -2 -53 -8 -105 -14 -134 -14 -170 -21 -170 -31 0 -5
1 -9 3 -9 38 3 300 40 303 43 7 6 -19 15 -31 11z"/>
<path d="M5070 7030 c0 -5 14 -10 30 -10 17 0 30 5 30 10 0 6 -13 10 -30 10
-16 0 -30 -4 -30 -10z"/>
<path d="M3975 7025 c-5 -2 -55 -7 -110 -11 -55 -4 -94 -9 -87 -11 6 -3 10 -8
8 -12 -3 -4 21 -4 52 0 31 4 82 8 113 8 32 1 63 7 75 16 18 14 18 14 -11 14
-16 0 -34 -2 -40 -4z"/>
<path d="M8630 6976 c0 -9 8 -12 25 -8 14 2 25 8 25 13 0 5 -11 9 -25 9 -14 0
-25 -6 -25 -14z"/>
<path d="M4610 6970 c-8 -5 -12 -11 -9 -14 7 -8 49 5 49 15 0 12 -21 11 -40
-1z"/>
<path d="M4708 6973 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4550 6956 c0 -9 7 -12 20 -9 11 3 17 9 14 14 -8 14 -34 10 -34 -5z"/>
<path d="M4431 6939 c-17 -7 -21 -13 -14 -20 12 -12 53 5 53 21 0 12 -7 12
-39 -1z"/>
<path d="M7423 6943 c-35 -2 -63 -7 -63 -12 0 -4 -28 -7 -62 -7 -91 0 -198
-14 -198 -26 0 -6 16 -8 43 -4 55 8 268 24 440 32 75 4 137 10 137 15 0 7
-177 8 -297 2z"/>
<path d="M7745 6940 c-14 -7 5 -9 60 -9 44 1 87 -1 95 -4 22 -8 80 -3 80 7 0
4 7 6 15 2 8 -3 15 -1 15 4 0 13 -237 12 -265 0z"/>
<path d="M8026 6933 c-4 -10 14 -13 82 -12 80 0 197 14 162 19 -8 1 -65 3
-127 4 -85 2 -114 -1 -117 -11z"/>
<path d="M8295 6940 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M4273 6893 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
<path d="M5965 6890 c-22 -4 -43 -6 -47 -3 -5 2 -8 -1 -8 -8 0 -9 17 -10 61
-5 34 3 75 6 90 6 16 0 29 5 29 10 0 11 -66 11 -125 0z"/>
<path d="M7010 6889 c0 -7 -9 -9 -22 -6 -13 3 -39 2 -58 -2 l-35 -8 27 -8 c27
-7 117 9 148 26 10 6 3 8 -22 9 -21 0 -38 -5 -38 -11z"/>
<path d="M4095 6879 c-27 -5 -77 -11 -110 -15 -52 -6 -57 -8 -35 -16 14 -5 45
-7 70 -3 25 4 61 9 80 11 93 10 120 16 120 25 0 11 -54 10 -125 -2z"/>
<path d="M5117 6883 c-3 -2 -48 -7 -99 -10 -51 -3 -97 -9 -103 -15 -6 -6 31
-7 105 -3 146 7 220 15 220 25 0 8 -115 12 -123 3z"/>
<path d="M5650 6879 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M6841 6861 c-9 -6 -10 -11 -2 -15 6 -4 18 0 27 8 12 12 12 16 2 16
-7 0 -19 -4 -27 -9z"/>
<path d="M4875 6850 c-4 -6 -13 -9 -21 -6 -8 3 -14 1 -14 -4 0 -13 37 -13 45
1 4 6 5 13 2 15 -3 3 -8 0 -12 -6z"/>
<path d="M3719 6827 c6 -6 24 -5 48 1 l38 11 -48 -2 c-29 0 -44 -4 -38 -10z"/>
<path d="M3888 6833 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M4730 6830 c0 -12 47 -12 75 0 15 6 8 9 -27 9 -27 1 -48 -3 -48 -9z"/>
<path d="M8767 6834 c-3 -3 -40 -8 -83 -9 -42 -2 -81 -8 -87 -14 -12 -12 92
-6 152 10 23 5 39 5 48 -3 8 -6 13 -7 13 0 0 5 -7 13 -16 16 -19 7 -19 7 -27
0z"/>
<path d="M4633 6823 c-18 -2 -33 -7 -33 -11 0 -4 -15 -8 -34 -9 -19 -2 -47 -8
-63 -14 -15 -7 -43 -13 -61 -14 -18 -1 -38 -8 -45 -15 -10 -10 -2 -11 38 -6
65 9 85 15 85 26 0 5 6 7 13 5 20 -8 150 19 155 33 2 6 -2 11 -10 10 -7 0 -28
-3 -45 -5z"/>
<path d="M8535 6800 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M8498 6793 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M7580 6778 c0 -10 5 -11 132 -12 81 -1 104 1 97 10 -11 14 -229 16
-229 2z"/>
<path d="M7876 6776 c5 -12 29 -15 130 -14 68 0 124 3 124 7 0 4 -55 9 -121
10 -67 1 -125 4 -130 7 -5 3 -6 -2 -3 -10z"/>
<path d="M8345 7530 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M10213 6513 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
<path d="M6128 6483 c-10 -2 -18 -9 -18 -14 0 -12 3 -12 36 6 28 14 19 18 -18
8z"/>
<path d="M6434 6476 c16 -9 31 -14 34 -11 8 8 0 13 -33 20 l-30 7 29 -16z"/>
<path d="M6595 6470 c-19 -8 -19 -9 3 -9 12 -1 22 4 22 9 0 6 -1 10 -2 9 -2 0
-12 -4 -23 -9z"/>
<path d="M5973 6463 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M1755 6229 c-231 -17 -409 -89 -552 -222 -165 -155 -250 -342 -274
-608 -20 -218 14 -427 97 -595 108 -219 299 -371 549 -436 79 -20 113 -23 285
-23 214 0 280 10 418 66 253 101 435 325 498 612 25 114 30 345 10 466 -30
175 -79 294 -173 419 -135 178 -369 300 -608 317 -38 3 -88 7 -110 9 -22 1
-85 -1 -140 -5z m253 -370 c136 -46 233 -169 279 -354 26 -106 23 -353 -5
-458 -42 -155 -129 -269 -249 -325 -52 -24 -69 -27 -173 -27 -105 0 -120 2
-170 27 -116 57 -189 147 -237 292 -26 76 -27 92 -28 266 0 167 2 192 23 260
33 106 77 182 134 236 111 103 274 136 426 83z"/>
<path d="M3134 6186 c-3 -8 -4 -301 -2 -653 4 -617 5 -641 26 -716 31 -114 76
-192 157 -272 154 -155 352 -213 687 -202 275 8 437 65 574 202 82 82 133 174
161 292 16 71 18 134 18 718 l0 640 -237 3 -237 2 -3 -632 c-3 -593 -4 -636
-22 -686 -26 -71 -83 -134 -148 -163 -46 -21 -68 -24 -163 -24 -132 0 -193 22
-254 91 -76 86 -75 79 -79 787 l-3 627 -235 0 c-193 0 -236 -3 -240 -14z"/>
<path d="M5010 6020 l0 -180 280 0 280 0 2 -732 3 -733 238 -3 237 -2 0 735 0
735 285 0 285 0 -2 178 -3 177 -802 3 -803 2 0 -180z"/>
<path d="M6890 5285 l0 -915 658 2 657 3 3 177 2 178 -422 2 -423 3 -3 733 -2
732 -235 0 -235 0 0 -915z"/>
<path d="M8480 5285 l0 -915 660 0 660 0 0 180 0 180 -425 0 -425 0 0 220 0
220 393 2 392 3 3 178 2 177 -395 0 -395 0 0 155 0 155 425 0 425 0 0 180 0
180 -660 0 -660 0 0 -915z"/>
<path d="M10010 6020 l0 -180 280 0 280 0 2 -732 3 -733 238 -3 237 -2 0 735
0 735 285 0 285 0 -2 178 -3 177 -802 3 -803 2 0 -180z"/>
<path d="M815 4110 c-11 -5 -58 -9 -105 -9 -48 -1 -91 -6 -97 -12 -10 -10 -62
-9 -168 5 -34 4 -35 4 -10 -3 l30 -9 -28 -1 -28 -1 7 -57 c3 -32 9 -62 11 -66
3 -4 55 -5 117 -1 105 6 114 5 127 -13 16 -22 102 -37 226 -37 39 -1 70 -3 68
-6 -2 -3 60 -4 137 -2 137 3 140 3 133 -17 -8 -25 -3 -26 37 -5 27 14 42 14
128 4 67 -9 106 -10 126 -3 23 8 44 5 99 -13 47 -16 91 -23 135 -22 36 0 55 3
43 5 -35 7 -27 19 13 20 20 1 39 5 43 11 3 5 26 9 51 8 33 -1 45 -5 45 -16 0
-23 24 -43 38 -33 7 4 19 8 27 7 8 -1 21 -2 28 -3 6 0 12 -6 12 -12 0 -6 -7
-8 -16 -5 -14 5 -15 4 -4 -9 10 -12 10 -15 0 -15 -7 0 -10 -7 -6 -15 3 -8 -2
-19 -11 -23 -11 -7 -14 -15 -8 -28 5 -11 9 -27 10 -37 3 -21 13 -46 94 -232
81 -185 208 -407 370 -648 46 -68 69 -112 62 -116 -6 -4 25 -49 79 -114 50
-59 90 -110 90 -113 0 -4 19 -28 41 -55 37 -44 43 -48 54 -33 10 15 12 14 10
-7 -2 -16 13 -40 44 -74 25 -27 68 -76 95 -107 27 -32 52 -55 57 -52 5 3 9 0
9 -6 0 -14 18 -33 115 -115 44 -38 87 -76 96 -84 8 -9 37 -34 63 -56 27 -22
65 -55 86 -72 43 -38 47 -39 54 -15 2 9 3 2 1 -15 -3 -29 3 -38 53 -78 32 -25
77 -52 102 -62 25 -9 77 -36 117 -60 40 -25 77 -42 83 -38 6 4 9 1 8 -6 -3
-12 27 -31 227 -149 130 -77 146 -87 158 -100 7 -7 -28 8 -77 34 -49 25 -90
45 -92 43 -2 -1 7 -15 19 -29 32 -39 521 -278 569 -278 8 0 18 -4 23 -8 6 -5
44 -21 85 -36 41 -15 107 -41 145 -58 98 -43 243 -80 266 -68 23 13 29 12 29
-1 0 -5 -5 -7 -12 -3 -6 4 -8 3 -5 -3 4 -6 18 -13 32 -15 19 -4 25 -1 25 13 0
11 4 19 10 19 5 0 7 -6 4 -14 -3 -8 0 -17 7 -21 15 -10 150 -37 344 -70 83
-14 163 -28 180 -31 l29 -6 -24 -20 c-20 -16 -33 -19 -85 -13 -33 3 -84 11
-113 17 -47 10 -54 9 -68 -8 -15 -18 -15 -18 -5 2 10 21 7 22 -57 34 -37 6
-87 18 -112 25 -30 9 -61 11 -92 6 -26 -4 -49 -4 -50 0 -5 13 -191 91 -238 99
-25 5 -82 18 -127 29 -45 12 -88 18 -97 15 -26 -10 -159 40 -393 148 -34 16
-81 35 -105 43 l-43 15 25 -19 c22 -18 49 -31 187 -93 23 -11 77 -32 120 -48
43 -16 123 -47 178 -70 144 -59 275 -100 315 -99 19 1 87 -16 150 -36 129 -42
132 -43 394 -85 104 -17 200 -29 212 -26 12 3 24 2 26 -3 4 -11 232 -28 478
-36 228 -7 270 -12 263 -31 -4 -11 8 -14 54 -14 82 0 269 19 318 32 78 21 117
25 153 17 39 -9 111 -1 93 10 -6 4 19 8 56 8 38 0 120 13 183 27 63 14 128 26
145 26 31 0 180 37 157 39 -7 1 -11 5 -8 10 3 5 -1 14 -8 20 -12 10 -13 9 -9
-7 2 -11 1 -22 -4 -25 -4 -3 -9 18 -10 46 0 29 1 43 4 32 8 -31 27 -37 35 -12
7 20 7 20 5 -3 -2 -18 4 -32 20 -43 13 -10 23 -13 23 -7 0 6 4 9 9 6 11 -7
114 31 126 47 6 8 15 12 22 10 6 -2 31 6 54 17 23 12 65 29 93 37 80 24 132
53 115 64 -24 16 -2 28 271 149 36 16 79 32 95 35 47 10 207 93 261 136 27 21
65 48 84 59 33 19 68 45 202 147 31 23 91 60 133 83 86 45 165 108 221 176
l37 44 -32 30 c-26 25 -28 30 -12 30 11 0 22 8 24 18 3 10 5 6 6 -10 0 -16 7
-28 14 -28 8 0 7 -4 -3 -10 -10 -6 -11 -10 -3 -10 13 0 129 97 172 143 12 14
20 30 17 36 -4 7 -1 8 9 5 10 -4 40 20 95 77 44 46 80 90 80 97 0 6 6 12 13
12 19 0 54 28 120 95 68 70 177 211 177 230 0 11 159 232 258 358 49 62 111
165 119 197 4 14 12 30 18 35 23 18 85 144 85 170 0 15 4 24 9 21 4 -3 14 14
21 37 7 23 21 59 31 79 11 21 19 46 19 56 0 10 13 45 28 78 29 58 63 155 96
267 17 55 18 57 52 57 19 0 44 5 55 11 34 18 158 38 162 26 2 -8 32 -7 99 2
83 11 101 10 133 -3 47 -20 61 -20 46 -2 -7 8 -8 17 -4 19 14 9 166 10 186 2
10 -4 23 -3 29 3 7 7 19 7 35 1 17 -6 22 -6 17 2 -4 8 1 9 17 4 31 -10 106 -8
149 5 19 5 53 10 75 10 30 0 44 5 54 21 12 21 17 21 132 15 l119 -7 0 28 c0
15 3 45 6 66 6 34 5 37 -17 38 l-24 1 25 9 c19 6 16 7 -15 3 -105 -14 -149
-15 -169 -5 -22 11 -108 18 -350 27 -122 4 -151 3 -157 -9 -5 -9 -28 -14 -66
-15 -78 -3 -115 -12 -129 -30 -9 -13 -23 -13 -109 -2 -54 6 -109 16 -122 21
-36 13 -90 12 -183 -6 -47 -8 -95 -13 -108 -10 -13 3 -66 6 -118 6 -52 1 -97
5 -100 10 -8 14 -34 11 -29 -3 4 -10 -17 -13 -82 -14 -193 -2 -408 -15 -411
-24 -2 -5 -13 -6 -27 0 -37 14 -153 15 -233 2 -40 -7 -83 -10 -95 -7 -37 9
-522 23 -525 15 -2 -4 -21 -4 -44 1 -22 6 -49 7 -59 4 -12 -4 -19 -2 -19 8 0
11 -20 13 -97 12 -113 -1 -146 3 -136 18 4 6 -4 7 -23 3 -102 -25 -347 -37
-314 -16 12 8 11 10 -7 10 -12 0 -23 -4 -25 -9 -5 -16 -512 -45 -573 -33 -14
2 -25 11 -25 19 0 10 -6 13 -17 9 -9 -4 -36 -10 -60 -13 -23 -3 -41 -11 -40
-17 1 -6 -10 -12 -26 -14 -15 -2 -25 -8 -22 -13 4 -5 -1 -9 -9 -9 -9 0 -16 7
-16 16 0 9 -6 14 -12 11 -17 -6 -109 43 -102 54 3 5 -6 9 -20 9 -14 0 -26 -4
-26 -10 0 -5 -9 -10 -19 -10 -11 0 -23 5 -26 10 -9 14 -62 12 -125 -6 -45 -12
-342 -30 -397 -24 -35 4 -123 2 -263 -7 -88 -6 -165 -7 -178 -2 -13 5 -89 7
-170 5 -81 -3 -214 -7 -297 -9 -142 -4 -226 2 -269 19 -15 6 -17 4 -11 -6 5
-9 4 -11 -4 -6 -6 4 -82 9 -169 11 -95 2 -181 10 -217 19 -70 18 -121 20 -130
6 -8 -13 -45 -13 -45 0 0 6 -12 10 -27 10 -17 0 -23 -3 -16 -8 8 -5 -13 -21
-60 -45 -72 -37 -93 -45 -83 -28 5 8 -121 18 -329 27 -60 2 -196 9 -302 14
-261 14 -274 13 -267 -5 5 -12 -3 -15 -35 -15 -42 0 -59 19 -18 21 15 1 17 3
5 6 -9 2 -33 -3 -53 -11 -21 -9 -39 -16 -41 -16 -10 0 -2 29 8 33 7 3 3 6 -9
6 -12 1 -34 7 -49 13 -22 10 -28 10 -32 0 -2 -7 -14 -10 -30 -6 -22 5 -24 3
-12 -6 12 -10 10 -10 -10 -5 -14 4 -47 6 -75 5 -27 -2 -61 -3 -75 -4 -14 0
-28 -4 -31 -9 -3 -4 -102 -10 -220 -13 -118 -3 -272 -9 -344 -13 -79 -5 -153
-5 -190 1 -106 17 -197 15 -227 -5 -23 -15 -27 -15 -30 -2 -2 13 -29 17 -163
22 -88 3 -199 6 -247 7 -47 1 -85 4 -83 7 5 7 -200 2 -232 -6 -13 -3 -71 2
-130 11 -60 8 -121 13 -138 10 -237 -41 -248 -42 -269 -21 -17 17 -43 23 -131
29 -32 2 -61 9 -63 15 -4 13 -276 14 -307 1z m7862 -55 c0 -8 -2 -15 -4 -15
-3 0 -11 7 -19 15 -14 13 -13 15 4 15 10 0 19 -7 19 -15z m-72 -15 c-3 -5 -15
-10 -26 -10 -10 0 -19 5 -19 10 0 6 12 10 26 10 14 0 23 -4 19 -10z m-842 -7
c-13 -2 -33 -2 -45 0 -13 2 -3 4 22 4 25 0 35 -2 23 -4z m-3723 -103 c0 -5 -7
-10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4 15 -10z m4535 0 c-3
-5 -10 -10 -16 -10 -5 0 -9 5 -9 10 0 6 7 10 16 10 8 0 12 -4 9 -10z m-5332
-28 c-4 -15 45 -21 156 -19 58 1 96 -2 98 -8 7 -21 130 -223 167 -275 21 -29
42 -66 47 -82 16 -59 365 -449 432 -484 15 -8 27 -19 27 -24 0 -6 7 -10 15
-10 8 0 15 -7 15 -15 0 -8 4 -15 8 -15 4 0 41 -32 82 -71 86 -82 135 -116 170
-121 14 -2 31 -9 39 -16 12 -11 15 -11 23 0 6 9 8 8 6 -6 -2 -15 29 -38 149
-110 84 -49 151 -92 149 -94 -2 -2 -22 6 -45 17 -48 25 -58 26 -40 5 24 -29
284 -152 475 -225 177 -67 246 -89 354 -109 25 -4 72 -13 105 -20 67 -12 235
-40 249 -40 5 0 3 -7 -4 -15 -15 -18 -46 -19 -127 -2 -57 12 -61 12 -50 -2 9
-13 7 -12 -8 1 -24 20 -98 39 -170 43 -28 2 -76 14 -107 28 -68 30 -201 66
-266 71 -30 3 -102 29 -196 70 -82 37 -151 64 -153 62 -19 -19 520 -229 560
-218 5 1 50 -11 100 -27 82 -26 208 -50 422 -80 39 -5 129 -13 200 -16 72 -3
170 -8 219 -10 49 -2 92 -9 96 -14 12 -21 192 -11 288 16 29 8 65 12 82 8 20
-4 30 -3 30 5 0 9 3 10 13 1 13 -10 32 -7 330 49 55 10 109 29 280 94 37 14
82 40 100 58 28 28 236 128 265 128 33 0 270 150 376 237 16 13 56 38 88 54
38 19 85 56 129 102 91 96 79 83 205 205 61 59 139 134 173 166 33 33 61 68
61 78 0 10 6 17 13 15 7 -1 13 10 15 26 2 20 8 27 17 23 15 -5 65 81 65 112 0
13 2 14 9 4 5 -9 28 16 75 83 38 52 72 97 76 100 5 3 38 59 74 124 61 110 69
119 101 125 19 4 34 12 32 19 -3 17 80 12 171 -9 60 -14 80 -15 106 -6 18 7
80 11 144 10 94 -1 110 -3 101 -15 -10 -11 -8 -12 14 -4 15 6 34 8 42 5 9 -4
15 0 15 9 0 10 8 15 20 14 22 -1 28 -17 8 -18 -7 0 -4 -4 7 -9 11 -5 35 -6 53
-3 18 3 66 2 108 -2 l75 -7 -7 -36 c-4 -21 -11 -38 -15 -40 -5 -2 -9 -7 -9
-12 0 -9 -82 -189 -105 -232 -8 -14 -14 -32 -12 -40 2 -7 2 -10 0 -6 -3 4 -17
-16 -32 -45 -71 -132 -105 -194 -122 -219 -11 -14 -19 -29 -19 -33 0 -14 -227
-347 -297 -435 -70 -89 -123 -152 -155 -186 -22 -22 -38 -45 -37 -50 4 -21 -2
-35 -11 -29 -5 3 -71 -54 -145 -126 -81 -79 -130 -136 -124 -140 8 -5 8 -9 -1
-14 -6 -4 -14 -4 -17 -1 -9 8 -34 -15 -27 -25 3 -5 -4 -9 -15 -9 -10 0 -31
-13 -44 -30 -14 -16 -29 -30 -33 -30 -6 0 -140 -105 -154 -121 -6 -6 -187
-133 -245 -171 -295 -193 -630 -355 -962 -467 -100 -33 -371 -107 -465 -126
-16 -3 -28 -12 -28 -20 0 -8 -4 -15 -10 -15 -5 0 -10 6 -10 13 0 11 -30 8
-140 -12 -92 -17 -140 -30 -140 -38 0 -7 7 -13 15 -13 8 0 15 5 15 10 0 6 5
10 11 10 5 0 7 -5 3 -12 -5 -8 2 -9 26 -5 18 4 40 7 49 7 14 0 14 2 1 11 -11
7 -5 8 21 3 24 -4 39 -2 44 6 5 8 10 9 14 1 5 -7 28 -5 76 5 76 17 101 22 280
54 478 84 956 295 1440 634 84 59 96 70 71 60 -13 -5 -17 -4 -12 4 4 7 14 12
22 12 8 0 14 5 14 11 0 5 4 8 9 5 24 -15 462 376 454 405 -2 5 4 9 12 9 17 0
185 186 185 206 0 8 5 13 12 11 11 -2 176 207 246 313 20 30 39 57 42 60 11 9
120 191 120 200 0 5 4 10 8 12 28 12 237 439 307 631 l18 47 63 0 63 0 -29
-82 c-33 -97 -70 -186 -134 -321 -25 -53 -43 -103 -40 -111 3 -7 -4 -29 -15
-47 -12 -19 -21 -39 -21 -46 0 -6 -10 -22 -22 -36 -12 -13 -44 -62 -72 -109
-74 -124 -200 -311 -239 -355 -18 -21 -62 -76 -98 -123 -73 -97 -169 -208
-256 -295 -174 -175 -342 -326 -443 -401 -204 -152 -303 -218 -355 -240 -20
-8 -68 -35 -108 -61 -134 -86 -377 -211 -539 -278 -38 -15 -68 -33 -68 -41 0
-16 -17 -19 -22 -4 -1 5 -47 -6 -101 -25 -105 -37 -218 -88 -177 -81 21 4 23
3 9 -7 -20 -15 -190 -63 -399 -112 -85 -21 -159 -41 -165 -46 -14 -14 -65 -11
-65 4 0 7 -3 8 -8 2 -4 -5 -16 -12 -27 -13 -11 -2 -25 -8 -32 -13 -7 -7 -14
-6 -18 1 -3 6 -12 7 -18 3 -7 -5 -19 -8 -27 -9 -8 -1 -23 -2 -32 -3 -11 0 -18
-9 -18 -22 0 -11 -4 -18 -9 -15 -5 4 -12 0 -14 -6 -3 -7 -6 -3 -6 9 -1 27 -9
32 -42 26 -19 -3 -30 -14 -37 -36 -9 -30 -10 -31 -12 -7 -1 14 -2 28 -1 33 1
10 -51 9 -134 -4 -114 -17 -607 -13 -770 7 -77 9 -168 18 -202 19 -35 2 -67 6
-73 10 -6 3 -7 0 -4 -9 4 -9 1 -16 -6 -16 -6 0 -14 9 -17 20 -3 11 -10 20 -15
20 -5 0 -6 -4 -3 -10 3 -5 -1 -10 -10 -10 -9 0 -13 4 -10 10 6 10 -3 13 -120
30 -126 18 -248 51 -550 147 -170 54 -633 259 -737 325 -47 31 -188 119 -348
219 -45 28 -153 111 -244 188 -18 15 -19 15 -14 -6 4 -13 2 -23 -3 -23 -5 0
-9 13 -9 28 0 20 -9 35 -31 51 -61 45 -149 135 -180 182 -17 27 -34 49 -37 49
-3 0 -15 -8 -26 -17 -21 -18 -21 -18 -2 5 l19 22 -108 108 c-122 121 -282 310
-335 395 -60 97 -161 251 -178 270 -21 24 -58 45 -47 26 11 -17 13 -79 3 -72
-5 3 -7 10 -5 17 3 6 -10 22 -27 35 -18 14 -51 60 -74 102 -33 61 -46 77 -60
73 -11 -4 -13 -3 -5 3 9 6 8 16 -4 41 -8 17 -19 29 -24 26 -5 -3 -9 4 -9 16 0
11 -26 71 -58 133 -32 61 -72 148 -90 192 -35 86 -57 127 -49 88 3 -15 1 -20
-9 -16 -8 3 -12 22 -12 56 1 31 -3 52 -10 55 -7 2 -12 13 -12 23 0 11 -4 18
-9 15 -5 -4 -12 0 -14 6 -3 8 -6 6 -6 -4 -1 -10 4 -18 10 -18 6 0 9 -7 6 -15
-4 -8 -1 -18 5 -21 8 -5 6 -10 -6 -14 -18 -6 -18 -7 -1 -24 10 -9 16 -18 14
-20 -12 -10 -59 -14 -64 -6 -3 5 1 10 10 10 10 0 15 7 13 18 -2 11 -12 17 -28
16 -28 -1 -29 9 -15 74 6 26 12 33 29 31 11 -1 28 1 36 4 8 3 122 8 253 11
131 2 241 7 244 10 4 3 0 6 -8 6 -11 0 -11 2 1 10 21 14 30 12 30 -4 0 -10 10
-14 29 -14 15 1 33 -2 39 -6 8 -4 10 -2 6 7 -4 12 13 15 102 16 60 0 122 -4
140 -11 26 -9 47 -8 106 5 118 27 175 30 171 9z m5757 8 c0 -5 -12 -10 -27 -9
-25 0 -26 1 -8 9 27 12 35 12 35 0z m-4935 -10 c-3 -5 -33 -10 -66 -10 -38 0
-58 4 -54 10 3 6 33 10 66 10 38 0 58 -4 54 -10z m4575 -16 c0 -9 10 -14 33
-13 l32 1 -30 8 c-27 7 -28 8 -5 8 14 1 43 -2 66 -7 22 -4 73 -4 113 1 39 5
85 5 101 1 l29 -8 -28 -46 c-28 -45 -31 -46 -92 -52 -34 -4 -82 -7 -105 -7
-24 0 -44 -4 -44 -9 0 -13 118 -13 157 0 19 6 36 9 38 7 3 -2 -19 -40 -48 -84
l-53 -79 -45 2 -44 1 37 -10 36 -10 -56 -74 -57 -74 -140 0 c-142 0 -202 -11
-128 -23 21 -4 63 -7 93 -6 42 0 50 3 35 10 -15 7 -10 8 20 4 22 -2 52 -5 68
-5 15 0 27 -4 27 -9 0 -5 -27 -38 -60 -74 -60 -64 -60 -65 -110 -62 -32 2 -50
-1 -50 -9 0 -8 -2 -8 -8 0 -8 13 -212 19 -212 6 0 -4 17 -8 38 -8 20 -1 86 -5
146 -10 l108 -9 -65 -62 -64 -62 26 -10 c21 -8 17 -9 -26 -6 -39 2 -51 0 -47
-9 6 -17 -128 -126 -155 -126 -10 0 -22 -6 -25 -12 -4 -8 -8 -9 -11 -3 -6 11
-115 7 -115 -4 0 -4 26 -9 57 -10 32 -1 59 -4 60 -5 5 -5 -107 -77 -175 -113
-43 -23 -76 -33 -106 -33 -63 0 -136 -11 -136 -21 0 -5 32 -6 70 -3 39 4 70 4
70 1 0 -3 -30 -23 -67 -43 -38 -20 -84 -47 -103 -59 -19 -12 -75 -36 -125 -54
-121 -44 -187 -71 -204 -85 -8 -6 -22 -10 -31 -8 -10 1 -17 -4 -17 -13 0 -8 3
-15 7 -15 4 0 7 -7 7 -15 0 -8 -4 -15 -9 -15 -4 0 -17 -9 -27 -19 -19 -19 -19
-19 -26 6 -4 17 -9 22 -18 16 -7 -5 -23 -10 -37 -11 -14 0 -85 -15 -157 -33
-127 -31 -133 -33 -134 -58 -1 -20 -3 -21 -6 -6 -3 16 -12 20 -43 20 -29 0
-40 -4 -40 -15 0 -11 -2 -12 -8 -3 -5 8 -22 11 -47 8 -22 -3 -71 -8 -110 -11
-38 -3 -97 -9 -130 -14 -33 -6 -77 -10 -97 -10 -24 0 -38 -5 -38 -12 0 -8 -3
-9 -8 -3 -11 15 -53 17 -69 3 -10 -8 -17 -8 -21 -1 -4 6 -37 11 -74 12 -37 0
-107 5 -155 10 -49 5 -97 9 -108 9 -28 1 -336 51 -357 58 -10 3 -16 8 -14 12
2 4 -15 8 -37 10 -56 3 -349 95 -334 104 7 4 10 8 7 9 -3 0 -20 2 -38 5 -32 4
-96 29 -201 77 l-54 25 61 13 c35 6 59 16 56 21 -4 6 2 7 15 3 11 -3 54 -2 94
4 39 6 84 12 100 13 15 1 27 8 27 16 0 10 -7 12 -22 9 -13 -3 -61 -11 -108
-16 -97 -12 -241 -39 -249 -46 -2 -3 -29 9 -58 25 -30 17 -59 28 -65 24 -7 -4
-8 -3 -4 4 6 11 -6 16 -26 13 -5 0 -8 3 -8 9 0 5 -30 29 -67 53 -113 73 -142
89 -153 82 -5 -3 -10 1 -10 9 0 8 12 16 27 19 23 3 22 4 -9 6 -36 1 -76 27
-153 99 l-29 27 37 3 c20 2 37 7 37 12 0 5 14 7 30 4 19 -4 30 -2 30 5 0 6 7
11 16 11 8 0 12 -4 9 -10 -3 -6 1 -7 10 -4 11 5 13 10 6 17 -7 7 -28 6 -67 -2
-98 -19 -134 -15 -175 22 -38 34 -74 87 -62 88 5 0 17 2 28 5 11 2 47 6 80 9
61 6 79 8 195 26 99 15 110 19 110 30 0 6 -11 8 -27 5 -27 -6 -215 -31 -313
-42 -25 -2 -57 -6 -71 -9 -20 -3 -40 9 -92 56 -39 35 -67 69 -67 80 0 10 -6
19 -14 19 -14 0 -116 108 -116 123 0 20 40 3 66 -28 16 -19 33 -35 38 -35 4 0
-4 16 -18 35 -22 28 -24 35 -11 35 9 0 13 5 10 10 -3 6 -16 10 -28 10 -29 0
-72 71 -54 89 8 8 8 11 -1 11 -7 0 -12 -5 -12 -12 0 -6 -3 -8 -7 -4 -4 4 -8
15 -8 24 0 12 -12 19 -42 23 -24 3 -44 7 -46 10 -2 2 -13 20 -25 40 -31 51
-51 67 -62 49 -7 -12 -12 -11 -29 4 -25 22 -27 36 -6 36 8 0 15 5 15 10 0 6
-11 10 -24 10 -27 0 -56 37 -53 69 1 21 4 21 72 15 85 -9 85 -9 160 -1 39 4
51 3 35 -3 -25 -8 193 -4 238 6 12 2 22 0 22 -6 0 -6 11 -8 28 -4 18 4 23 3
14 -4 -9 -6 3 -11 39 -15 51 -6 76 4 63 25 -8 12 75 9 99 -4 30 -16 51 -38 37
-38 -6 0 -8 -5 -4 -11 8 -13 81 -6 97 10 8 8 3 11 -17 11 -15 0 -25 4 -21 9 3
6 22 4 47 -5 51 -18 93 -18 110 -1 10 10 43 13 131 11 119 -3 151 -9 126 -25
-7 -4 -11 -11 -7 -14 3 -3 23 6 44 20 39 27 43 27 103 3 26 -10 35 -10 38 -1
3 9 11 9 31 2 27 -11 80 -10 97 2 23 15 110 30 113 20 2 -6 17 -11 33 -11 16
0 29 -4 29 -10 0 -13 -31 -13 -64 0 -20 7 -33 5 -58 -9 -35 -20 -145 -40 -118
-22 8 6 10 11 5 11 -6 0 -16 -5 -23 -12 -6 -6 -22 -13 -34 -15 -12 -2 -25 -9
-29 -15 -4 -6 -16 -8 -28 -5 -12 3 -24 1 -27 -5 -8 -13 97 -2 184 19 34 8 69
13 76 10 7 -3 32 -1 57 4 24 5 89 10 144 13 55 2 138 7 184 11 63 6 82 5 77
-3 -4 -7 -3 -12 3 -12 5 0 13 5 17 11 5 9 51 10 173 4 91 -4 198 -7 236 -6
201 5 233 5 270 2 22 -2 112 2 199 9 146 12 160 12 171 -3 7 -10 16 -17 20
-17 4 0 2 7 -5 15 -16 19 -5 19 120 1 72 -11 98 -12 109 -3 7 7 40 11 72 11
32 0 56 3 53 8 -3 4 8 8 23 8 16 0 33 4 39 10 7 7 28 5 64 -6 87 -25 107 -27
140 -16 24 9 30 8 30 -4 0 -12 5 -12 38 2 61 26 66 26 106 -1 21 -14 41 -23
44 -20 4 3 0 10 -8 15 -8 5 -12 11 -9 14 3 3 67 8 143 11 127 5 138 4 132 -11
-6 -14 -1 -16 34 -10 106 16 125 18 110 7 -31 -22 26 -11 76 14 42 22 139 37
121 19 -3 -4 0 -12 7 -19 14 -14 100 -9 91 5 -3 4 51 7 119 6 68 0 119 2 113
6 -7 4 -23 8 -37 9 -14 1 -5 5 20 8 51 8 60 5 60 -15z m-6916 -5 c5 -5 7 -11
5 -14 -6 -5 -125 18 -133 26 -9 9 118 -3 128 -12z m9273 15 c-5 -6 -119 -33
-140 -34 -5 0 -7 6 -5 13 3 7 30 14 64 18 32 3 65 6 73 7 8 1 12 -1 8 -4z
m-4784 -31 c-7 -2 -21 -2 -30 0 -10 3 -4 5 12 5 17 0 24 -2 18 -5z m184 1 c-3
-3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-4327 -154 c0 -5 -4 -10
-10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m0 -24 c0 -3 -4
-8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m1834 -78 c-3
-4 7 -25 21 -47 49 -78 20 -84 -32 -7 -39 58 -41 66 -9 64 14 -1 22 -6 20 -10z
m6663 -40 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m-90 0 c-3 -8
-6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m76 -48 c3 -11 1 -23 -4 -26 -5
-3 -9 6 -9 20 0 31 6 34 13 6z m-29 -35 c11 -8 15 -15 10 -15 -6 0 -15 5 -22
12 -9 9 -12 5 -13 -17 0 -25 -2 -27 -8 -10 -8 20 -4 45 8 45 3 0 15 -7 25 -15z
m-48 -87 c6 4 14 14 17 22 5 13 12 -30 8 -52 -2 -13 17 -9 22 5 3 7 6 5 6 -5
1 -19 -7 -22 -33 -12 -9 3 -14 10 -11 14 3 5 1 11 -5 15 -6 3 -10 -4 -11 -17
-1 -23 -1 -23 -9 2 -4 14 -8 36 -8 50 0 24 1 24 7 -2 4 -17 10 -25 17 -20z
m-1570 -10 c5 -8 2 -9 -9 -5 -10 4 -17 1 -17 -5 0 -6 -3 -9 -6 -5 -7 7 4 27
16 27 5 0 12 -5 16 -12z m1501 -10 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4
-12 1 -19z m-1790 -140 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z
m1360 16 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-1360 -156
c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m13 -42 c0 -3 -4 -8 -10
-11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m1727 -18 c-3 -8 -6
-5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-6077 -22 c0 -15 -7 -26 -19 -29
-14 -3 -18 -1 -14 9 3 8 10 11 16 7 6 -4 8 2 5 15 -4 12 -2 22 3 22 5 0 9 -11
9 -24z m5570 -46 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4
-4 4 -10z m-5590 -10 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6
0 11 -4 11 -10z m4190 -20 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11
10 2 0 4 -4 4 -10z m-4056 -36 c8 -22 7 -34 -3 -34 -5 0 -11 11 -13 25 -6 28
6 34 16 9z m5325 -87 c0 -27 -2 -29 -9 -12 -7 17 -9 15 -9 -12 -1 -46 -21 -41
-21 5 0 38 16 66 32 56 4 -3 8 -20 7 -37z m-134 -135 c10 -26 -11 -34 -22 -9
-9 19 -8 24 3 24 7 0 16 -7 19 -15z m-2050 -122 c3 -5 1 -10 -4 -10 -6 0 -11
5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m2205 -69 c0 -11 -5 -23 -11 -27 -8
-4 -9 1 -4 16 5 17 4 21 -5 15 -9 -5 -11 -4 -6 3 11 18 26 14 26 -7z m-355
-81 c-3 -5 -10 -10 -16 -10 -5 0 -9 5 -9 10 0 6 7 10 16 10 8 0 12 -4 9 -10z
m235 -10 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10
-10z m-4099 -17 c13 -16 12 -17 -3 -4 -10 7 -18 15 -18 17 0 8 8 3 21 -13z
m1629 13 c0 -2 -8 -10 -17 -17 -16 -13 -17 -12 -4 4 13 16 21 21 21 13z m2610
-36 c0 -11 -4 -20 -9 -20 -5 0 -7 9 -4 20 3 11 7 20 9 20 2 0 4 -9 4 -20z
m-505 -10 c10 -11 16 -20 13 -20 -3 0 -13 9 -23 20 -10 11 -16 20 -13 20 3 0
13 -9 23 -20z m606 -8 c-7 -2 -19 -1 -29 3 -14 5 -14 7 -2 15 9 5 20 4 28 -3
10 -8 11 -12 3 -15z m-2787 -26 c-20 -15 -34 -12 -34 7 0 8 3 8 9 -1 6 -10 10
-10 21 3 7 8 15 13 18 10 3 -3 -3 -12 -14 -19z m-1479 -6 c10 -11 16 -20 13
-20 -3 0 -13 9 -23 20 -10 11 -16 20 -13 20 3 0 13 -9 23 -20z m395 -1 c0 -5
-4 -9 -10 -9 -5 0 -10 7 -10 16 0 8 5 12 10 9 6 -3 10 -10 10 -16z m850 13 c0
-5 -7 -9 -15 -9 -8 0 -12 4 -9 9 3 4 9 8 15 8 5 0 9 -4 9 -8z m60 -32 c0 -5
-2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m-906 -49
c-7 -9 -24 13 -24 32 0 21 1 21 13 -3 8 -14 12 -27 11 -29z m864 24 c-3 -3 -9
2 -12 12 -6 14 -5 15 5 6 7 -7 10 -15 7 -18z m3039 -27 c-3 -7 -5 -2 -5 12 0
14 2 19 5 13 2 -7 2 -19 0 -25z m-2887 19 c0 -2 -12 -14 -27 -28 l-28 -24 24
28 c23 25 31 32 31 24z m-3845 -27 c-3 -5 -13 -10 -21 -10 -8 0 -14 5 -14 10
0 6 9 10 21 10 11 0 17 -4 14 -10z m3656 -40 c6 0 22 -11 36 -24 22 -21 28
-22 40 -10 18 17 24 18 17 1 -2 -7 -14 -18 -25 -25 -13 -9 -18 -9 -14 -2 4 7
1 11 -6 9 -29 -3 -39 2 -39 21 0 11 -7 20 -15 20 -9 0 -15 9 -14 23 1 16 2 18
6 5 2 -10 9 -18 14 -18z m-3601 -3 c0 -2 -12 -14 -27 -28 l-28 -24 24 28 c23
25 31 32 31 24z m3337 -19 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1
-19z m263 -18 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10
-4 10 -10z m-3660 -23 c0 -2 -10 -12 -22 -23 l-23 -19 19 23 c18 21 26 27 26
19z m6341 -24 c13 -16 12 -17 -3 -4 -10 7 -18 15 -18 17 0 8 8 3 21 -13z m49
-48 c13 -14 21 -25 18 -25 -2 0 -15 11 -28 25 -13 14 -21 25 -18 25 2 0 15
-11 28 -25z m51 -52 c13 -16 12 -17 -3 -4 -10 7 -18 15 -18 17 0 8 8 3 21 -13z
m-765 -40 c-11 -11 -17 4 -9 23 7 18 8 18 11 1 2 -10 1 -21 -2 -24z m-5356 -1
c0 -4 -9 -8 -19 -8 -11 0 -17 4 -15 8 3 4 12 8 20 8 8 0 14 -4 14 -8z m5950 0
c0 -4 -10 -9 -22 -9 -18 -2 -20 0 -8 7 18 12 30 13 30 2z m56 -17 c1 -14 -2
-25 -7 -25 -10 0 -12 33 -2 43 3 4 7 7 7 7 1 0 2 -11 2 -25z m-5995 -26 c8
-15 8 -19 0 -15 -7 5 -11 -2 -12 -16 0 -22 -1 -22 -8 -3 -5 11 -4 23 2 26 5 4
6 12 3 18 -4 6 -5 11 -2 11 3 0 10 -10 17 -21z m5939 -31 c0 -18 -2 -20 -9 -8
-6 8 -7 18 -5 22 9 14 14 9 14 -14z m17 -40 c-3 -8 -6 -5 -6 6 -1 11 2 17 5
13 3 -3 4 -12 1 -19z m-5992 -18 c7 0 16 8 18 18 4 15 11 -30 8 -50 -2 -13 17
-9 22 5 3 7 6 6 6 -5 1 -9 -2 -19 -6 -21 -10 -6 -45 7 -44 16 3 21 0 27 -14
27 -9 0 -15 9 -14 23 1 16 2 18 6 5 2 -10 11 -18 18 -18z m5692 -22 c-3 -8 -6
-5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m263 -18 c0 -5 -4 -10 -10 -10 -5
0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-5640 -39 c7 -14 7 -22 0
-26 -6 -4 -10 -3 -9 2 4 21 -2 34 -13 27 -7 -4 -8 -3 -4 4 10 16 14 15 26 -7z
m5350 9 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z
m-5533 -92 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m5362 6 c2
-2 -10 -4 -27 -4 -18 0 -32 5 -32 10 0 9 47 4 59 -6z m201 -5 c0 -5 -4 -9 -10
-9 -5 0 -10 7 -10 16 0 8 5 12 10 9 6 -3 10 -10 10 -16z m-5246 -54 c-4 -8 -8
-15 -10 -15 -2 0 -4 7 -4 15 0 8 4 15 10 15 5 0 7 -7 4 -15z m4076 -145 c-10
-6 -11 -10 -2 -10 17 0 15 -17 -2 -23 -10 -4 -13 -1 -9 9 3 8 -1 14 -8 14 -11
0 -11 3 1 10 8 5 20 10 25 10 6 0 3 -5 -5 -10z m-332 -122 c-6 -18 -28 -21
-28 -4 0 9 7 16 16 16 9 0 14 -5 12 -12z m-3199 -39 c-15 -9 -32 -2 -24 11 3
5 13 6 21 3 12 -4 13 -8 3 -14z m3661 11 c0 -5 -6 -10 -14 -10 -8 0 -18 5 -21
10 -3 6 3 10 14 10 12 0 21 -4 21 -10z m-3600 -15 c0 -8 -4 -15 -10 -15 -5 0
-10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z m3565 -34 c-3 -6 -11 -11 -17
-11 -6 0 -6 6 2 15 14 17 26 13 15 -4z m80 -41 c3 -5 1 -10 -4 -10 -6 0 -11 5
-11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m59 -56 c4 -10 1 -14 -6 -12 -15 5 -23
28 -10 28 5 0 13 -7 16 -16z m-3367 -11 c-3 -5 -18 -8 -34 -7 -41 3 -42 6 -3
13 39 8 45 7 37 -6z m3329 2 c-3 -8 -1 -15 4 -15 6 0 10 -7 10 -15 0 -20 -26
-19 -34 1 -3 8 0 21 7 29 16 19 20 19 13 0z m-94 -47 c-9 -9 -15 -9 -24 0 -9
9 -7 12 12 12 19 0 21 -3 12 -12z m78 -39 c0 -5 -7 -6 -15 -3 -8 4 -15 13 -15
21 0 13 3 13 15 3 8 -7 15 -16 15 -21z m-77 1 c-3 -11 -9 -20 -14 -20 -14 0
-11 28 4 33 6 3 13 6 14 6 0 1 -1 -8 -4 -19z m-3368 -10 c3 -5 1 -10 -4 -10
-6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m3405 -9 c10 -20 -4 -41
-17 -27 -4 3 0 6 8 6 10 1 9 3 -3 11 -19 11 -24 29 -8 29 5 0 14 -9 20 -19z
m-373 -113 c-2 -13 -4 -5 -4 17 -1 22 1 32 4 23 2 -10 2 -28 0 -40z m318 42
c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m-995
-10 c0 -5 -8 -10 -17 -10 -15 0 -16 2 -3 10 19 12 20 12 20 0z m-1460 -20 c0
-5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m1404 -5
c-6 -16 -24 -21 -24 -7 0 11 11 22 21 22 5 0 6 -7 3 -15z m-1777 -27 c-3 -7
-5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m323 28 c0 -3 -4 -8 -10 -11 -5
-3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m150 0 c0 -2 -8 -10 -17 -17
-16 -13 -17 -12 -4 4 13 16 21 21 21 13z m1380 -17 c0 -5 -4 -9 -10 -9 -5 0
-10 7 -10 16 0 8 5 12 10 9 6 -3 10 -10 10 -16z m-1940 -3 c0 -3 -4 -8 -10
-11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m2525 -6 c3 -5 1 -10
-4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m-855 -10 c0 -5 -2
-10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m862 -57 c-4
-23 -6 -24 -15 -7 -7 12 -7 19 1 22 6 2 8 11 5 20 -4 13 -3 14 5 4 5 -7 7 -25
4 -39z m48 37 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11
-4 11 -10z m-2094 -29 c0 -11 -5 -17 -13 -14 -10 4 -11 9 -2 19 15 18 15 18
15 -5z m1189 -11 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8
-4 11 -10z m-945 -16 c0 -8 -4 -12 -10 -9 -5 3 -10 10 -10 16 0 5 5 9 10 9 6
0 10 -7 10 -16z m1235 -54 c10 -11 16 -20 13 -20 -3 0 -13 9 -23 20 -10 11
-16 20 -13 20 3 0 13 -9 23 -20z m272 -12 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13
3 -3 4 -12 1 -19z m-192 -68 c10 -11 16 -20 13 -20 -3 0 -13 9 -23 20 -10 11
-16 20 -13 20 3 0 13 -9 23 -20z"/>
<path d="M2305 3788 c3 -67 17 -103 121 -313 80 -162 217 -403 280 -491 16
-23 44 -63 63 -90 216 -307 558 -658 884 -908 100 -76 324 -227 407 -274 136
-76 185 -102 197 -102 7 0 13 -4 13 -9 0 -14 317 -160 360 -164 8 -1 21 -8 29
-15 11 -11 16 -9 28 8 8 12 13 23 12 25 -2 1 -33 15 -69 30 -122 51 -385 184
-500 253 -78 47 -117 66 -122 58 -5 -7 -8 -5 -8 6 0 9 -17 27 -37 39 -62 38
-244 169 -313 227 -36 29 -85 70 -110 90 -25 20 -76 66 -113 100 -68 64 -68
64 -90 45 -22 -18 -22 -18 -5 3 17 19 17 21 0 36 -110 103 -432 475 -432 500
0 6 -7 7 -16 4 -8 -3 -12 -2 -9 4 5 8 -20 57 -36 70 -21 17 -189 285 -273 438
-34 62 -66 109 -71 105 -4 -5 -5 -3 -2 3 5 9 -74 207 -89 223 -2 2 -10 -1 -18
-8 -11 -9 -15 -7 -19 11 -4 13 -1 27 5 33 8 7 9 5 5 -7 -4 -10 -2 -18 3 -18
11 0 14 33 3 43 -3 4 -11 3 -17 -3 -8 -6 -12 4 -14 37 -2 52 0 48 -28 57 -19
6 -20 3 -19 -46z m98 -193 c-3 -9 -8 -14 -10 -11 -3 3 -2 9 2 15 9 16 15 13 8
-4z m79 -170 c3 -13 1 -22 -3 -19 -9 5 -13 44 -5 44 2 0 6 -11 8 -25z"/>
<path d="M3805 3240 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M3830 3221 c0 -7 18 -34 41 -61 23 -27 38 -53 35 -58 -6 -11 230
-242 247 -242 5 0 7 7 4 15 -4 8 -2 17 4 20 5 4 9 3 8 -2 -3 -19 2 -33 12 -33
15 0 39 -26 31 -34 -3 -4 1 -4 11 0 10 3 17 1 17 -5 0 -6 -4 -11 -10 -11 -22
0 -7 -19 40 -51 27 -19 50 -37 50 -41 0 -11 68 -10 74 1 9 14 -21 44 -108 111
-58 44 -82 56 -88 47 -6 -8 -8 -7 -6 5 2 10 -21 41 -57 76 -33 32 -74 74 -92
93 -43 47 -151 143 -166 148 -7 2 -20 10 -29 19 -13 10 -18 11 -18 3z"/>
<path d="M4460 2656 c0 -7 163 -106 173 -106 14 0 -30 32 -101 73 -63 37 -72
41 -72 33z"/>
<path d="M8223 1470 c-72 -34 -118 -54 -212 -91 -13 -6 -22 -12 -19 -14 10
-11 318 125 318 140 0 7 4 8 -87 -35z"/>
<path d="M4707 1443 c-12 -11 -8 -43 6 -48 6 -2 57 -19 112 -38 255 -89 591
-173 712 -179 35 -2 63 -6 63 -10 0 -5 15 -8 33 -8 17 0 50 -4 72 -9 72 -16
236 -23 251 -10 11 9 14 8 14 -5 0 -21 39 -21 47 -1 3 9 4 18 1 20 -2 2 -48 8
-103 14 -76 8 -101 7 -107 -2 -5 -8 -8 -8 -8 -1 0 6 -24 14 -53 17 -29 4 -67
10 -84 13 -25 5 -32 2 -36 -12 -4 -17 -5 -17 -6 -2 0 10 -11 20 -23 23 -121
27 -225 44 -240 39 -10 -3 -18 -1 -18 4 0 12 -114 36 -135 29 -9 -4 -15 -1
-15 7 0 16 -51 28 -64 15 -14 -13 -26 -11 -26 4 0 17 -80 41 -93 28 -16 -16
-28 -13 -25 6 4 23 -135 70 -140 47 -6 -23 -32 -16 -32 9 0 28 -58 56 -72 34
-5 -8 -8 -7 -8 6 0 17 -11 22 -23 10z"/>
<path d="M6615 1168 c-44 -5 -194 -10 -332 -11 -230 -2 -253 -4 -253 -19 0
-15 22 -17 234 -18 146 0 237 3 241 9 3 6 19 8 35 4 50 -9 260 17 260 33 0 16
-56 17 -185 2z"/>
<path d="M7240 3850 c-13 -9 -13 -11 -1 -19 18 -12 101 -24 109 -17 3 3 -1 6
-10 6 -8 0 -23 9 -33 20 -20 22 -42 25 -65 10z"/>
<path d="M4385 3825 l-30 -14 34 2 c19 1 36 8 39 15 5 15 -5 15 -43 -3z"/>
<path d="M4227 3816 c-45 -6 -75 -15 -70 -20 4 -5 -5 -6 -22 -1 -16 4 -86 7
-153 6 -96 -2 -122 1 -116 10 5 9 -1 10 -26 6 -18 -4 -42 -7 -54 -7 -15 0 -17
-3 -8 -9 7 -4 23 -6 36 -4 12 3 28 -2 35 -10 9 -12 29 -15 84 -12 40 2 126 7
192 10 121 5 189 17 200 35 7 11 9 11 -98 -4z"/>
<path d="M6353 3820 c-33 -14 -28 -20 15 -19 29 0 32 2 17 8 l-20 8 20 5 c11
3 13 6 5 6 -8 0 -25 -3 -37 -8z"/>
<path d="M7107 3824 c-8 -9 1 -24 14 -24 6 0 8 4 4 9 -3 5 5 12 17 14 21 4 21
4 -3 6 -14 0 -29 -2 -32 -5z"/>
<path d="M7425 3820 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M6195 3810 c-16 -7 -12 -9 18 -9 23 -1 36 3 32 9 -7 12 -23 12 -50 0z"/>
<path d="M7009 3793 c-13 -15 -11 -16 25 -10 54 9 71 27 24 27 -21 0 -41 -7
-49 -17z"/>
<path d="M6945 3780 c-3 -5 3 -10 14 -10 11 0 23 5 26 10 3 6 -3 10 -14 10
-11 0 -23 -4 -26 -10z"/>
<path d="M6880 3770 c-18 -12 -2 -12 25 0 13 6 15 9 5 9 -8 0 -22 -4 -30 -9z"/>
<path d="M7980 3755 c0 -6 41 -11 100 -12 57 -1 100 2 100 8 0 11 -17 12 -122
13 -46 1 -78 -3 -78 -9z m137 -1 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17
-2 13 -5z"/>
<path d="M8198 3763 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M8670 3760 l-25 -6 23 -8 c24 -8 37 -3 31 12 -2 4 -15 5 -29 2z"/>
<path d="M6720 3752 c0 -4 18 -8 40 -8 22 0 40 3 40 8 0 4 -18 8 -40 8 -22 0
-40 -4 -40 -8z"/>
<path d="M8308 3753 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M8350 3750 c0 -5 16 -7 35 -6 19 2 35 0 35 -3 0 -5 39 -6 148 -2 12
1 22 5 22 10 0 5 -54 9 -120 9 -66 0 -120 -4 -120 -8z"/>
<path d="M4883 3743 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M6595 3741 c-27 -3 -45 -9 -40 -12 18 -11 119 -3 123 9 4 13 -1 13
-83 3z"/>
<path d="M4763 3730 c-13 -5 -23 -14 -23 -21 0 -6 7 -8 16 -5 9 3 19 6 24 6 4
0 10 7 14 15 6 17 1 18 -31 5z"/>
<path d="M6403 3723 c-83 -4 -100 -23 -20 -23 77 0 157 11 151 21 -3 5 -17 8
-32 7 -15 -1 -60 -4 -99 -5z"/>
<path d="M7455 3720 l-30 -6 34 -7 c38 -8 43 -6 33 9 -4 5 -20 7 -37 4z"/>
<path d="M3875 3710 c-27 -7 -25 -8 18 -9 26 0 47 4 47 9 0 11 -21 11 -65 0z"/>
<path d="M5520 3708 c0 -12 118 -9 125 2 4 7 -16 10 -59 8 -36 -2 -66 -6 -66
-10z"/>
<path d="M5660 3710 c0 -7 54 -10 165 -9 90 0 169 -2 175 -6 5 -3 10 0 10 9 0
12 -28 15 -175 16 -118 0 -175 -3 -175 -10z"/>
<path d="M6206 3712 c-2 -1 -23 -6 -47 -11 -44 -9 -44 -9 21 -10 70 -1 140 8
140 18 0 6 -109 9 -114 3z"/>
<path d="M7385 3710 c-18 -8 -17 -9 8 -9 15 -1 27 4 27 9 0 12 -8 12 -35 0z"/>
<path d="M4675 3700 c-3 -5 1 -10 9 -10 9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0
-13 -4 -16 -10z"/>
<path d="M5381 3701 c-7 -5 -9 -11 -5 -13 13 -8 124 3 124 12 0 10 -103 12
-119 1z"/>
<path d="M4597 3687 c-4 -10 2 -13 20 -9 18 3 21 7 11 13 -18 12 -25 11 -31
-4z"/>
<path d="M5270 3690 c20 -13 90 -13 90 0 0 6 -24 10 -52 10 -38 0 -49 -3 -38
-10z"/>
<path d="M4490 3670 c0 -6 7 -10 15 -10 8 0 15 2 15 4 0 2 -7 6 -15 10 -8 3
-15 1 -15 -4z"/>
<path d="M5093 3673 c-40 -8 -26 -26 15 -19 20 3 43 6 50 6 6 0 12 5 12 10 0
10 -34 11 -77 3z"/>
<path d="M4285 3660 c-33 -3 -69 -12 -80 -19 -17 -10 -13 -11 29 -7 27 3 66 6
86 6 24 0 40 5 44 15 3 9 0 14 -7 14 -7 -1 -39 -5 -72 -9z"/>
<path d="M5015 3654 l-30 -13 33 1 c18 1 32 8 32 15 0 7 -1 13 -2 12 -2 0 -16
-7 -33 -15z"/>
<path d="M4870 3633 c-30 -8 -73 -18 -95 -22 -22 -5 -35 -9 -30 -10 6 -1 3 -6
-5 -11 -23 -15 3 -12 43 5 20 8 52 15 71 15 19 0 38 5 41 11 4 6 13 8 21 5 14
-5 30 10 17 17 -5 2 -33 -2 -63 -10z"/>
<path d="M6979 3635 c-91 -15 -119 -23 -119 -35 0 -11 56 -4 135 16 33 8 73
17 89 19 16 3 32 7 34 10 9 8 -59 4 -139 -10z"/>
<path d="M3941 3630 c-9 -5 -5 -9 10 -13 28 -7 31 -6 14 10 -6 6 -18 8 -24 3z"/>
<path d="M4035 3630 c-3 -5 3 -10 15 -10 12 0 18 5 15 10 -3 6 -10 10 -15 10
-5 0 -12 -4 -15 -10z"/>
<path d="M7977 3602 c-69 -6 -27 -22 58 -22 64 0 84 3 89 15 6 17 -3 19 -38 5
-14 -5 -36 -6 -48 -2 -13 4 -40 6 -61 4z"/>
<path d="M4698 3593 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6784 3589 c-3 -5 6 -9 20 -9 31 0 34 6 6 13 -11 3 -23 1 -26 -4z"/>
<path d="M8255 3590 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M8280 3590 c0 -5 23 -10 50 -10 28 0 50 5 50 10 0 6 -22 10 -50 10
-27 0 -50 -4 -50 -10z"/>
<path d="M8460 3591 c0 -5 53 -10 117 -10 64 -1 114 1 111 4 -10 10 -228 16
-228 6z"/>
<path d="M4630 3582 c-40 -6 -44 -19 -6 -17 35 1 55 9 39 17 -4 2 -19 2 -33 0z"/>
<path d="M8408 3583 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6493 3572 c-29 -2 -53 -8 -53 -14 0 -11 224 -2 247 11 13 7 -105 9
-194 3z"/>
<path d="M7620 3570 c0 -5 12 -10 26 -10 14 0 23 4 19 10 -3 6 -15 10 -26 10
-10 0 -19 -4 -19 -10z"/>
<path d="M4525 3560 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M5640 3560 c-47 -3 -86 -10 -87 -15 -1 -10 172 -3 183 8 11 10 -12
12 -96 7z"/>
<path d="M5755 3560 c-3 -5 3 -10 15 -10 12 0 18 5 15 10 -3 6 -10 10 -15 10
-5 0 -12 -4 -15 -10z"/>
<path d="M5940 3551 c0 -6 5 -13 10 -16 6 -3 10 1 10 9 0 9 -4 16 -10 16 -5 0
-10 -4 -10 -9z"/>
<path d="M6375 3550 c-19 -8 -19 -9 3 -9 12 -1 22 4 22 9 0 6 -1 10 -2 9 -2 0
-12 -4 -23 -9z"/>
<path d="M4410 3540 c-8 -5 -10 -10 -5 -10 6 0 17 5 25 10 8 5 11 10 5 10 -5
0 -17 -5 -25 -10z"/>
<path d="M5398 3545 c-3 -3 -43 -7 -89 -10 -112 -6 -261 -29 -492 -75 -21 -4
-44 -13 -50 -20 -10 -10 -5 -11 23 -6 274 56 297 59 480 75 174 15 210 21 210
32 0 9 -73 13 -82 4z"/>
<path d="M6310 3540 c-13 -8 -12 -10 3 -10 9 0 17 5 17 10 0 12 -1 12 -20 0z"/>
<path d="M7323 3543 c-33 -6 -28 -23 7 -23 17 0 30 5 30 11 0 7 10 9 25 7 13
-3 22 -1 19 3 -5 9 -41 10 -81 2z"/>
<path d="M4255 3534 c-11 -2 -55 -6 -97 -9 -43 -4 -78 -11 -78 -17 0 -6 14 -8
38 -3 20 4 68 7 107 6 74 -1 105 5 105 20 0 9 -31 10 -75 3z"/>
<path d="M7235 3520 c-3 -5 -17 -10 -31 -10 -13 0 -24 -5 -24 -11 0 -6 7 -8
16 -5 9 3 33 9 55 12 21 4 36 10 33 15 -7 12 -41 11 -49 -1z"/>
<path d="M6995 3475 c-92 -18 -104 -23 -75 -27 38 -6 230 30 230 44 0 12 -31
8 -155 -17z"/>
<path d="M6815 3435 c-19 -15 -19 -15 10 -13 17 2 29 7 28 13 -3 16 -18 16
-38 0z"/>
<path d="M6725 3430 c-25 -11 1 -11 35 0 20 6 21 8 5 8 -11 0 -29 -3 -40 -8z"/>
<path d="M7855 3430 c-38 -7 -38 -7 18 -9 32 0 57 3 57 9 0 11 -14 11 -75 0z"/>
<path d="M7940 3430 c0 -5 7 -7 15 -4 8 4 15 8 15 10 0 2 -7 4 -15 4 -8 0 -15
-4 -15 -10z"/>
<path d="M8095 3430 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M8120 3431 c0 -5 21 -9 48 -9 34 0 41 3 27 8 -28 12 -75 12 -75 1z"/>
<path d="M7655 3420 c-27 -4 -53 -5 -57 -3 -4 2 -9 -1 -13 -7 -4 -6 -11 -8
-16 -5 -5 4 -9 1 -9 -5 0 -8 15 -10 48 -6 98 12 187 20 195 18 5 -2 6 2 2 8
-7 11 -73 11 -150 0z"/>
<path d="M6440 3395 c0 -11 11 -15 39 -15 25 0 41 5 45 15 4 12 -4 15 -39 15
-33 0 -45 -4 -45 -15z"/>
<path d="M5545 3395 c-5 -3 -39 -6 -75 -7 -36 -2 -64 -7 -62 -12 2 -10 177 -9
232 1 33 6 34 7 10 14 -27 8 -89 10 -105 4z"/>
<path d="M5686 3391 c-4 -5 3 -7 14 -4 23 6 26 13 6 13 -8 0 -17 -4 -20 -9z"/>
<path d="M6278 3393 c-30 -8 -20 -23 16 -23 55 0 126 12 126 21 0 9 -110 10
-142 2z"/>
<path d="M7400 3388 c-25 -5 -54 -10 -65 -12 -11 -3 -33 -5 -48 -5 -16 -1 -26
-5 -23 -9 7 -11 100 -3 104 8 2 5 15 7 30 4 15 -2 34 0 42 5 22 15 9 18 -40 9z"/>
<path d="M5768 3383 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M6045 3380 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M6115 3380 c-4 -6 18 -10 60 -10 42 0 64 4 60 10 -3 6 -30 10 -60 10
-30 0 -57 -4 -60 -10z"/>
<path d="M5308 3373 c-10 -2 -18 -9 -18 -14 0 -12 17 -12 25 0 3 5 22 7 43 5
28 -4 33 -3 22 5 -15 11 -41 12 -72 4z"/>
<path d="M4235 3360 c3 -5 24 -10 45 -10 21 0 42 5 45 10 4 6 -13 10 -45 10
-32 0 -49 -4 -45 -10z"/>
<path d="M4115 3351 c7 -12 65 -11 65 1 0 4 -16 8 -36 8 -19 0 -33 -4 -29 -9z"/>
<path d="M5112 3351 c-23 -14 11 -21 68 -14 33 4 47 9 38 14 -17 11 -89 11
-106 0z"/>
<path d="M7183 3352 c-13 -4 -19 -12 -15 -18 8 -14 52 0 52 16 0 11 -4 11 -37
2z"/>
<path d="M5000 3330 c0 -5 -16 -9 -35 -8 -20 1 -41 -5 -48 -12 -10 -10 -7 -11
13 -7 14 3 46 9 73 12 54 8 73 25 28 25 -17 0 -31 -4 -31 -10z"/>
<path d="M7025 3319 c-22 -5 -53 -12 -70 -14 -76 -12 -175 -37 -175 -45 0 -9
61 -4 90 7 8 4 19 5 24 4 4 -1 14 3 20 9 7 5 16 7 19 4 3 -3 27 -1 54 5 26 6
61 13 76 16 15 3 25 10 22 15 -7 11 -5 11 -60 -1z"/>
<path d="M4250 3300 c-34 -7 -34 -7 13 -9 26 0 47 4 47 9 0 11 -8 11 -60 0z"/>
<path d="M7985 3280 c-18 -8 -17 -9 8 -9 16 -1 26 3 22 9 -3 6 -7 10 -8 9 -1
0 -11 -4 -22 -9z"/>
<path d="M8065 3280 c-9 -15 12 -20 65 -19 32 2 47 6 41 12 -12 12 -99 18
-106 7z"/>
<path d="M4778 3273 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6715 3260 c-4 -6 -10 -8 -13 -6 -4 2 -23 1 -42 -4 -33 -8 -32 -8 10
-5 25 2 53 4 63 4 9 1 17 6 17 11 0 13 -27 13 -35 0z"/>
<path d="M7755 3260 c-4 -6 10 -10 35 -10 25 0 39 4 35 10 -3 6 -19 10 -35 10
-16 0 -32 -4 -35 -10z"/>
<path d="M7650 3250 c-11 -7 -5 -10 23 -10 20 0 37 5 37 10 0 13 -40 13 -60 0z"/>
<path d="M6345 3240 c-66 -4 -155 -8 -197 -9 -45 0 -78 -5 -78 -11 0 -6 52
-10 138 -10 99 0 133 3 122 11 -11 8 -6 9 22 4 44 -7 138 4 138 16 0 5 -6 8
-12 7 -7 -1 -67 -5 -133 -8z"/>
<path d="M6514 3239 c-3 -5 11 -9 30 -9 45 0 55 16 10 17 -19 1 -37 -3 -40 -8z"/>
<path d="M7445 3239 c-38 -5 -72 -13 -75 -18 -3 -4 -27 -9 -54 -10 -50 -1 -66
-6 -66 -21 0 -9 15 -7 149 16 46 8 95 14 110 14 21 0 23 2 11 10 -10 7 3 8 41
4 31 -4 59 -3 62 2 7 12 -94 14 -178 3z"/>
<path d="M5495 3230 l-30 -7 35 -7 c27 -6 106 -3 140 4 3 0 74 1 158 0 99 -1
152 3 152 10 0 11 -405 12 -455 0z"/>
<path d="M5228 3206 c-48 -6 -90 -14 -94 -18 -4 -5 -35 -9 -68 -9 -79 -2 -118
-11 -124 -28 -4 -11 2 -12 24 -8 47 11 294 46 367 52 37 4 67 11 67 16 0 11
-59 10 -172 -5z"/>
<path d="M7213 3192 c-13 -2 -23 -8 -23 -13 0 -9 37 -3 49 7 8 7 -4 10 -26 6z"/>
<path d="M7074 3168 c-23 -5 -41 -14 -41 -22 0 -10 10 -11 46 -3 25 5 51 14
56 20 7 7 6 8 -2 3 -6 -3 -13 -2 -15 2 -1 4 -21 4 -44 0z"/>
<path d="M6948 3143 c-14 -2 -29 -11 -32 -19 -5 -12 1 -14 32 -9 65 12 76 16
61 26 -8 5 -18 8 -24 7 -5 0 -22 -3 -37 -5z"/>
<path d="M4778 3123 c-16 -2 -28 -8 -28 -13 0 -9 43 -5 110 10 35 8 34 8 -10
7 -25 0 -57 -2 -72 -4z"/>
<path d="M6785 3115 c-44 -8 -106 -14 -138 -14 -32 -1 -56 -5 -53 -9 7 -12 81
-13 136 -2 25 5 67 11 94 12 28 2 51 9 54 16 2 6 0 11 -5 11 -4 -1 -44 -7 -88
-14z"/>
<path d="M8056 3122 c-17 -17 4 -22 88 -22 61 0 97 4 102 12 5 8 -9 10 -54 6
-34 -4 -77 -3 -96 2 -19 4 -37 5 -40 2z"/>
<path d="M7940 3109 c0 -5 14 -9 31 -9 16 0 28 4 25 9 -3 5 -17 9 -31 9 -14 0
-25 -4 -25 -9z"/>
<path d="M7668 3102 c-44 -4 -52 -22 -9 -22 49 0 121 12 121 21 0 8 -36 8
-112 1z"/>
<path d="M7596 3090 c-15 -5 -16 -9 -7 -14 7 -5 18 -3 24 3 14 14 6 20 -17 11z"/>
<path d="M4580 3080 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z"/>
<path d="M6455 3080 c-18 -7 -17 -8 8 -15 32 -8 43 -3 25 12 -7 6 -22 7 -33 3z"/>
<path d="M6520 3080 c0 -5 9 -10 19 -10 11 0 23 5 26 10 4 6 -5 10 -19 10 -14
0 -26 -4 -26 -10z"/>
<path d="M7520 3078 c0 -5 7 -8 15 -8 8 0 18 0 22 -1 4 0 9 4 11 9 2 6 -8 9
-23 8 -14 0 -25 -4 -25 -8z"/>
<path d="M5636 3069 c3 -5 -25 -6 -61 -2 -52 5 -65 3 -65 -8 0 -12 19 -13 109
-8 125 7 191 29 79 27 -37 -1 -65 -5 -62 -9z"/>
<path d="M5825 3069 c17 -5 47 -7 68 -4 20 3 37 1 37 -3 0 -5 84 -9 188 -9
l187 -1 -150 14 c-181 15 -376 17 -330 3z"/>
<path d="M6308 3073 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5430 3053 c0 -7 7 -13 15 -13 8 0 15 6 15 13 0 8 -7 14 -15 14 -8 0
-15 -6 -15 -14z"/>
<path d="M7397 3058 c-20 -12 -3 -18 36 -11 20 3 37 10 37 14 0 11 -55 8 -73
-3z"/>
<path d="M5195 3039 c-77 -10 -185 -27 -240 -39 -55 -11 -116 -24 -135 -27
-19 -3 -46 -15 -60 -26 -20 -16 -21 -19 -5 -13 25 10 291 61 363 71 28 4 52
11 52 16 0 4 7 6 15 3 22 -9 216 17 209 27 -7 12 -31 10 -199 -12z"/>
<path d="M7310 3042 c0 -5 7 -9 15 -9 8 0 15 4 15 9 0 4 -7 8 -15 8 -8 0 -15
-4 -15 -8z"/>
<path d="M7168 3023 c-10 -2 -18 -9 -18 -14 0 -6 -8 -8 -17 -5 -10 3 -31 2
-48 -3 l-30 -9 37 -1 c48 -1 139 20 132 31 -5 8 -27 9 -56 1z"/>
<path d="M6980 2980 l-25 -8 28 -1 c15 -1 27 4 27 9 0 6 -1 10 -2 9 -2 -1 -14
-5 -28 -9z"/>
<path d="M7020 2980 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M6775 2952 c-91 -16 -110 -18 -290 -27 -71 -4 -132 -9 -134 -11 -3
-2 1 -10 7 -16 8 -8 12 -8 12 -1 0 7 22 9 63 6 61 -4 412 29 423 40 19 18 -11
22 -81 9z"/>
<path d="M6906 2962 c-2 -4 4 -9 15 -9 10 -1 19 2 19 8 0 11 -27 12 -34 1z"/>
<path d="M7940 2950 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M4663 2938 c-34 -11 -46 -29 -15 -23 60 12 72 16 72 25 0 12 -14 11
-57 -2z"/>
<path d="M7890 2940 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>
<path d="M7605 2933 c-51 -7 -55 -25 -6 -21 25 2 46 9 49 16 2 6 0 11 -5 11
-4 -1 -21 -4 -38 -6z"/>
<path d="M7756 2930 c6 -6 21 -6 39 -1 30 9 30 9 -9 10 -26 1 -37 -2 -30 -9z"/>
<path d="M5440 2905 c-69 -8 -135 -14 -147 -14 -15 -1 -21 -6 -17 -15 3 -8 11
-13 17 -11 7 2 73 9 147 15 227 17 260 21 260 31 0 12 -107 10 -260 -6z"/>
<path d="M5820 2910 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M6190 2900 c0 -5 19 -10 42 -10 49 0 47 5 -4 14 -24 4 -38 3 -38 -4z"/>
<path d="M7399 2893 c-12 -15 -12 -16 4 -10 10 4 25 7 33 7 8 0 14 5 14 10 0
16 -36 11 -51 -7z"/>
<path d="M7518 2903 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5178 2877 c-38 -6 -68 -14 -68 -19 0 -4 -27 -8 -59 -8 -33 0 -62 -4
-66 -9 -3 -6 -14 -8 -25 -6 -12 2 -20 -2 -20 -9 0 -9 9 -12 25 -8 14 2 25 8
25 12 0 5 9 6 19 3 11 -3 44 0 73 7 54 12 117 21 154 24 11 0 21 7 21 14 0 6
-3 11 -6 10 -3 0 -36 -6 -73 -11z"/>
<path d="M7183 2859 c-25 -9 -30 -19 -10 -19 15 0 43 17 37 23 -3 3 -15 1 -27
-4z"/>
<path d="M7108 2838 c9 -9 15 -9 24 0 9 9 7 12 -12 12 -19 0 -21 -3 -12 -12z"/>
<path d="M4822 2810 c-90 -23 -100 -30 -36 -25 63 5 144 25 144 36 0 13 -24
10 -108 -11z"/>
<path d="M6913 2813 c-19 -3 -33 -10 -33 -18 0 -10 10 -12 38 -8 50 7 112 23
112 28 0 6 -67 4 -117 -2z"/>
<path d="M6782 2790 c-18 -4 -35 -10 -38 -13 -5 -4 11 -4 94 2 6 1 12 6 12 11
0 11 -20 11 -68 0z"/>
<path d="M6367 2772 c-9 -2 -15 -8 -12 -13 4 -5 -15 -10 -42 -10 -75 -3 -81
-4 -89 -11 -9 -10 155 -11 219 -2 31 5 47 12 50 24 4 15 -3 18 -44 19 -36 0
-49 -3 -49 -13 0 -8 6 -17 13 -19 6 -3 2 -6 -10 -6 -25 -1 -40 15 -21 22 19 6
6 14 -15 9z"/>
<path d="M6630 2771 c0 -5 22 -9 50 -8 65 3 65 17 0 17 -27 0 -50 -4 -50 -9z"/>
<path d="M7593 2772 c-24 -2 -43 -8 -43 -13 0 -6 -12 -8 -27 -5 -16 4 -48 2
-73 -3 -31 -6 -37 -9 -20 -11 l25 -2 -25 -9 c-23 -8 -22 -8 5 -4 66 11 83 13
153 20 39 4 72 11 72 15 0 12 -20 15 -67 12z"/>
<path d="M6517 2758 c2 -5 28 -8 56 -8 39 1 48 3 35 11 -19 11 -99 8 -91 -3z"/>
<path d="M5617 2753 c-14 -13 -6 -24 15 -21 27 4 36 28 11 28 -11 0 -23 -3
-26 -7z"/>
<path d="M5691 2748 c14 -16 290 -18 301 -4 4 6 13 5 22 -3 8 -7 21 -9 27 -5
26 16 -30 23 -194 23 -134 1 -165 -2 -156 -11z"/>
<path d="M5445 2745 c-5 -2 -36 -6 -67 -10 -32 -4 -58 -11 -58 -17 0 -7 18 -8
53 -3 28 4 75 8 102 9 28 2 58 6 67 10 9 4 21 5 27 2 5 -4 12 -2 15 3 6 8
-114 13 -139 6z"/>
<path d="M7366 2725 c4 -8 8 -15 10 -15 2 0 4 7 4 15 0 8 -4 15 -10 15 -5 0
-7 -7 -4 -15z"/>
<path d="M7295 2720 c-16 -7 -13 -9 18 -9 20 -1 37 4 37 9 0 12 -27 12 -55 0z"/>
<path d="M7193 2703 c-25 -5 -32 -23 -9 -23 32 0 75 13 70 21 -5 9 -22 10 -61
2z"/>
<path d="M7123 2685 c-17 -7 -33 -11 -35 -9 -3 2 -47 -5 -98 -16 -52 -11 -110
-20 -129 -21 -20 0 -45 -4 -56 -9 -19 -7 -19 -8 -2 -15 10 -4 23 -3 30 4 6 6
31 11 57 11 58 0 162 17 169 28 3 5 25 8 48 8 45 0 58 6 52 23 -3 6 -16 4 -36
-4z"/>
<path d="M6730 2621 c0 -5 -17 -9 -37 -9 -101 3 -147 -2 -136 -13 8 -8 43 -9
117 -4 84 5 104 10 99 21 -6 15 -43 20 -43 5z"/>
<path d="M5795 2589 c-20 -4 -5 -7 45 -8 41 -1 85 -4 98 -7 17 -5 21 -3 16 9
-4 12 -20 15 -67 14 -34 -1 -75 -4 -92 -8z"/>
<path d="M6395 2591 c-38 -5 -45 -8 -30 -14 26 -11 132 3 114 14 -8 5 -18 8
-24 7 -5 -1 -32 -4 -60 -7z"/>
<path d="M5423 2583 c-24 -2 -43 -8 -43 -12 0 -4 -22 -6 -49 -4 -31 2 -52 -1
-57 -8 -5 -9 17 -10 97 -4 57 4 115 9 129 10 14 2 49 4 78 4 29 1 51 5 48 9
-5 9 -129 12 -203 5z"/>
<path d="M5998 2581 c20 -13 289 -14 297 -2 4 8 -43 11 -152 11 -89 0 -152 -4
-145 -9z"/>
<path d="M7350 2560 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M7268 2553 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M7226 2541 c-3 -4 -16 -6 -30 -4 -13 3 -37 -2 -52 -11 l-29 -15 33
-1 c23 0 31 4 26 12 -5 8 -2 9 10 5 10 -4 36 -4 59 0 33 5 37 8 22 14 -27 10
-33 10 -39 0z"/>
<path d="M7070 2499 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M7285 2502 c-15 -10 -13 -34 3 -39 7 -3 12 5 12 21 0 29 0 29 -15 18z"/>
<path d="M6955 2494 c-5 -2 -59 -10 -120 -19 -60 -9 -114 -21 -120 -27 -7 -7
5 -8 40 -4 28 3 77 8 110 12 66 7 184 30 191 37 6 5 -87 7 -101 1z"/>
<path d="M6451 2441 c-59 -4 -86 -10 -89 -20 -4 -11 14 -12 104 -6 60 4 111 9
113 10 8 8 -12 25 -27 23 -9 -1 -55 -4 -101 -7z"/>
<path d="M6600 2440 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>
<path d="M6658 2438 c17 -8 26 -8 34 0 9 9 4 12 -22 11 -33 0 -34 -1 -12 -11z"/>
<path d="M5650 2429 c0 -6 -7 -9 -15 -5 -8 3 -15 1 -15 -4 0 -16 86 -12 100 5
11 13 7 15 -29 15 -23 0 -41 -5 -41 -11z"/>
<path d="M5749 2429 c-9 -6 3 -9 36 -10 28 0 79 -2 115 -3 38 -2 68 2 72 8 5
7 8 6 8 -4 0 -13 1 -13 10 0 6 10 10 11 10 3 0 -7 9 -13 20 -13 11 0 20 7 20
15 0 14 -20 16 -138 14 -77 0 -145 -5 -153 -10z"/>
<path d="M6075 2420 c3 -5 26 -9 50 -8 57 3 47 18 -11 18 -27 0 -43 -4 -39
-10z"/>
<path d="M6190 2420 c0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 -9 10 -20 10 -11
0 -20 -4 -20 -10z"/>
<path d="M6240 2420 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M6280 2420 c0 -5 16 -10 35 -10 19 0 35 5 35 10 0 6 -16 10 -35 10
-19 0 -35 -4 -35 -10z"/>
<path d="M10515 4100 c3 -5 16 -10 28 -10 18 0 19 2 7 10 -20 13 -43 13 -35 0z"/>
<path d="M9765 4089 c-27 -3 -45 -7 -40 -8 6 -1 1 -7 -10 -12 -14 -6 -1 -7 40
-3 65 6 98 18 74 25 -8 3 -36 2 -64 -2z"/>
<path d="M1835 3800 c-5 -9 -4 -15 3 -18 7 -2 12 -11 12 -21 0 -9 7 -26 15
-37 14 -18 15 -17 9 11 -11 49 -30 80 -39 65z"/>
<path d="M2710 2278 c11 -18 45 -60 75 -93 42 -45 55 -65 51 -81 -8 -29 21
-55 35 -33 8 11 9 9 5 -6 -7 -26 65 -102 84 -90 8 5 11 3 8 -6 -3 -8 16 -35
41 -60 75 -74 295 -264 363 -314 35 -25 68 -49 73 -53 6 -5 30 -11 53 -14 31
-5 42 -3 42 7 0 8 10 15 22 17 18 2 7 14 -58 62 -80 61 -266 209 -295 236 -9
8 -31 28 -49 43 -19 16 -51 52 -72 80 -37 50 -141 147 -158 147 -4 0 -13 -7
-20 -15 -7 -9 -15 -13 -18 -10 -3 3 3 12 14 19 18 14 17 17 -26 61 -25 25 -68
59 -95 74 -28 16 -57 36 -64 45 -24 28 -31 17 -11 -16z m308 -298 c-7 -11 -17
-20 -22 -20 -5 0 -1 9 9 20 10 11 20 20 22 20 2 0 -2 -9 -9 -20z m169 -172
c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z"/>
<path d="M3640 1455 c33 -40 244 -165 257 -152 2 3 -5 8 -16 12 -12 3 -21 10
-21 14 0 8 -135 94 -200 126 -33 17 -34 17 -20 0z"/>
</g>
</svg>

export { outlet}