
export default  function SuccesNoPayment() {     
 
    return (<>
        <div>
            <div className="shop-no-success">
                <h4>Transacción Cancelada!</h4>
                <p>Su transacción ha sido rechazada,consulte con su entidad bancaria.</p>
            </div>
        </div>
    </>)
}